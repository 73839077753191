
import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import ExerciseContentEditor from './ExerciseContentEditor';
import ExerciseVariantPicker from './ExerciseVariantPicker';
import OverwriteContentModal from './OverwriteContentModal';
import TemplatePicker from './TemplatePicker';
import ExerciseAnswers from './ExerciseAnswers';
import ExerciseConfigurationSection from '../ExerciseConfigurationSection';
import ExerciseAccordion from '../ExerciseAccordion';
import ExerciseTemplatePreviewModal from './ExerciseTemplatePreviewModal';
import {
  AnswersType,
  BooleanAnswerType,
  Bundle,
  ExerciseCreateData,
  ExerciseAnswer,
  ExerciseAnswerAdditional,
  ExerciseHint,
  Source,
  Variant
} from '@/models/exercises';
import { Modal, ModalBody, ModalHeader, Panel, PanelBody, PanelHeader, TextField } from '@/shared/components';
import {
  NeButton,
  NeCheckbox,
  NeCollapse,
  NeCollapseHeader,
  NeCollapseIndicator,
  NeIcon,
  NeRadioButton
} from '@ne/ne-vue2-lib';
import { ExerciseAnswerMovePayload } from '@/views/Generator/ExerciseComposer/models/ExerciseAnswerMovePayload';
import { swap } from '@/helpers/order';
import ExerciseAnswersTemplatePreview
  from './ExerciseAnswers/ExerciseAnswersTemplatePreview/ExerciseAnswersTemplatePreview.vue';
import { answersTypes } from '@/shared/data/exercises';
import { wait } from '@/api/generator/helpers';
import ExerciseBundles from './ExerciseBundles/ExerciseBundles.vue';
import { deepClone } from '@/helpers/object-manipulation';
import ExerciseTemplatePicker from './ExerciseTemplatePicker/ExerciseTemplatePicker.vue';
import ExerciseBundlePicker from './ExerciseBundlePicker/ExerciseBundlePicker.vue';
import ConfirmationModal from '@/shared/components/ConfirmationModal/ConfirmationModal.vue';
import { EditorContentType } from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/models';
import ExerciseAssignment from './ExerciseAssignment/ExerciseAssignment.vue';
import ExerciseJustification from './ExerciseJustification/ExerciseJustification.vue';
import ExerciseHints
  from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseHints/ExerciseHints.vue';
import ExerciseExplanation
  from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExercsieExplanation/ExerciseExplanation.vue';
import TinyEditor from '@/shared/components/TinyEditor/TinyEditor.vue';

@Component({
  name: 'ExerciseContent',
  components: {
    ExerciseExplanation,
    ExerciseJustification,
    ExerciseAssignment,
    ConfirmationModal,
    ExerciseTemplatePicker,
    ExerciseBundlePicker,
    ExerciseBundles,
    ExerciseAccordion,
    ExerciseAnswers,
    ExerciseAnswersTemplatePreview,
    ExerciseConfigurationSection,
    ExerciseContentEditor,
    ExerciseHints,
    ExerciseTemplatePreviewModal,
    ExerciseVariantPicker,
    Modal,
    ModalBody,
    ModalHeader,
    NeButton,
    NeIcon,
    NeRadioButton,
    NeCheckbox,
    NeCollapse,
    NeCollapseHeader,
    NeCollapseIndicator,
    OverwriteContentModal,
    Panel,
    PanelBody,
    PanelHeader,
    TemplatePicker,
    TextField,
    TinyEditor
  }
})
export default class ExerciseContent extends Vue {
  @Prop() answersType: AnswersType;
  @Prop() earlySchoolEducation: boolean | null;
  @Prop() emptyAnswer: ExerciseAnswer;
  @Prop() exercise: ExerciseCreateData;
  @Prop() isExerciseChangeDisabled: boolean;
  @Prop() isExerciseEdit: boolean;
  @Prop() isExerciseHeightValidationActive: boolean;
  @Prop() isExerciseOldType: boolean;
  @Prop() isLoaded: boolean;
  @Prop() isPreviewModalShown: boolean;
  @Prop() isSuperUser: boolean;
  @Prop() isValidationActive: boolean;
  @Prop() isRedactor: boolean;
  @Prop() isRedactorNsml: boolean;
  @Prop() maxContentHeight: number;
  @Prop() pageHeight: number;
  @Prop() stringifiedErrors: string;
  @PropSync('currentVariantIndex') variantIndex: number;
  @PropSync('currentBundleIndex') bundleIndex: number;

  answerTemplatesText: string[];
  answerTypeEnum = AnswersType;
  editorContentTypes = EditorContentType;
  editorToInsertTemplate = '';
  emptyHint: ExerciseHint = { content: '' };
  englishTemplate = 0;
  fakeExerciseVariantIndex = 0;
  isContentEditorHovered = false;
  isContentModified = false;
  isOldAnswerKeyRemoveModalShown = false;
  isOverwriteContentModalShown = false;
  isRemoveVariantModalShown = false;
  isSolutionModified = false;
  isTemplatePickerShown = false;
  shouldFocus = false;

  get validationErrors (): any[] {
    return JSON.parse(this.stringifiedErrors);
  }

  get currentExerciseBundle (): Bundle | null {
    return this.currentExerciseVariant.bundles ? this.currentExerciseVariant.bundles[this.bundleIndex] : null;
  }

  get currentExerciseVariant (): Variant {
    return this.exercise.variants[this.variantIndex];
  }

  get currentVariantContent (): string {
    return this.exercise.variants[this.variantIndex].content;
  }

  set currentVariantContent (content: string) {
    this.exercise.variants[this.variantIndex].content = content;
  }

  get currentVariantSolution (): string | undefined {
    return this.exercise.variants[this.variantIndex].solution;
  }

  set currentVariantSolution (solution: string | undefined) {
    this.exercise.variants[this.variantIndex].solution = solution;
  }

  get showContentIsNotEditableInfo (): boolean {
    return this.isContentEditorHovered && !this.isCurrentVariantEditable;
  }

  get showOldAnswerKey (): boolean {
    if (this.isExerciseEdit) {
      return this.currentExerciseVariant.answerKey
        ? !(this.exercise.author === Source.NOWA_ERA && (!this.isExerciseOldType || this.answersType === AnswersType.BUNDLES)) || this.isSuperUser
        : this.isExerciseOldType;
    }
    return this.isExerciseOldType;
  }

  get showOldAnswerKeyInfo (): boolean {
    return (!this.isExerciseOldType || this.answersType === AnswersType.BUNDLES) && this.isExerciseEdit && this.currentExerciseVariant.answerKey;
  }

  get isDeleteSolutionBtnShown (): boolean {
    if (this.currentExerciseVariant.answerKey) {
      return (this.exercise.author === Source.MY || this.isSuperUser) && !this.isExerciseOldType;
    }
    return false;
  }

  get isCurrentVariantEditable (): boolean {
    return this.isSuperUser || this.currentExerciseVariant.author === Source.MY;
  }

  get isEnglish (): boolean | undefined {
    return this.exercise.editor?.includes('angielski');
  }

  get isGerman (): boolean | undefined {
    return this.exercise.editor?.includes('niemiecki');
  }

  get englishOptions () {
    const answerType = answersTypes.find(type => type.id === this.answersType);
    return this.isEnglish ? answerType?.options?.english : null;
  }

  get isExerciseBundle () {
    return [AnswersType.BUNDLES_EXAM, AnswersType.BUNDLES].includes(this.answersType);
  }

  get isAnswerConfigurationVisible () {
    return ![
      AnswersType.BUNDLES_EXAM,
      AnswersType.BUNDLES,
      AnswersType.FLASHCARD
    ].includes(this.answersType);
  }

  created (): void {
    this.answerTemplatesText = [
      this.$tc('EXERCISE_ANSWER.one_column'),
      this.$tc('EXERCISE_ANSWER.two_columns'),
      this.$tc('EXERCISE_ANSWER.more_columns')
    ];
  }

  get currentExerciseVariantAnswersStringify (): string {
    return JSON.stringify(this.exercise.variants[this.fakeExerciseVariantIndex].answers);
  }

  get currentVariantHasAnswers (): boolean {
    return !!this.currentExerciseVariant.answers?.length;
  }

  get exerciseStringify (): string {
    return JSON.stringify(this.exercise);
  }

  get scoreFromAllBundles (): number {
    return this.exercise.variants[0].bundles!.map((bundle) => bundle.score).reduce((prev: number, next: number) => prev + next);
  }

  @Watch('isLoaded')
  setInitialIndex (): void {
    if (this.isExerciseEdit) {
      this.setVariantIndex(parseInt(this.$route.params.variantIndex) - 1);
      this.updateExerciseHeightInEachVariant();
    }
  }

  @Watch('variantIndex')
  variantIndexWatcher () {
    this.fakeExerciseVariantIndex = this.variantIndex;
  }

  @Watch('currentExerciseBundle')
  currentExerciseBundleChangeHandler () {
    if ([AnswersType.BUNDLES, AnswersType.BUNDLES_EXAM].includes(this.exercise.answersType as AnswersType) && this.currentExerciseBundle) {
      this.exercise.score.value = this.scoreFromAllBundles;
    }
  }

  async countVariantHeight (index: number): Promise<void> {
    this.fakeExerciseVariantIndex = index;
    await wait(500);
    const exercise = document.querySelector('#gt-fake-exercise') as HTMLElement;
    const element = exercise ? exercise.shadowRoot!.querySelector('.gt-exercise') : null;
    if (element) this.exercise.variants[index].printHeight = element!.children[0].clientHeight;
  }

  async updateExerciseHeightInEachVariant (): Promise<void> {
    await wait(50);
    const tmpVariantIndex = this.fakeExerciseVariantIndex;
    for (const variant of this.exercise.variants) {
      const index: number = this.exercise.variants.indexOf(variant);
      await this.countVariantHeight(index);
    }
    this.fakeExerciseVariantIndex = tmpVariantIndex;
  }

  cmpDidUpdated (ev: CustomEvent): void {
    this.currentExerciseVariant.printHeight = ev.detail.height;
  }

  isContentInValid (exerciseHeight: number): boolean {
    return this.isExerciseHeightValidationActive && (exerciseHeight > this.maxContentHeight);
  }

  setVariantIndex (index: number): void {
    this.variantIndex = index;
    this.bundleIndex = 0;
  }

  setAnswerKeyStatus (): void {
    const solution = this.currentExerciseVariant.solution;
    this.currentExerciseVariant.answerKey = solution !== '';
  }

  showRemoveVariantModal (): void {
    this.isRemoveVariantModalShown = true;
  }

  removeVariant (): void {
    const variantIndex: number = this.variantIndex;
    this.setVariantIndex(0);
    if (!this.exercise.variants) this.exercise.variants = [];
    this.exercise.variants.splice(variantIndex, 1);
    this.bundleIndex = 0;
  }

  addVariant (variant: Variant): void {
    this.exercise.variants.push(JSON.parse(JSON.stringify(variant)));
    this.setVariantIndex(this.exercise.variants.length - 1);
  }

  handleTemplateButtonClick (editorToInsertTemplate: EditorContentType): void {
    this.editorToInsertTemplate = editorToInsertTemplate;
    let content;
    switch (editorToInsertTemplate) {
    case EditorContentType.BUNDLE_SUB_CONTENT:
      content = this.currentExerciseBundle!.subContent;
      break;
    case EditorContentType.ADDITIONAL_CONTENT:
      content = this.exercise.additionalContent?.content;
      break;
    default:
      content = this.currentExerciseVariant.content;
      break;
    }
    if (content) {
      this.isOverwriteContentModalShown = true;
    } else {
      this.isTemplatePickerShown = true;
    }
  }

  showTemplatePicker (): void {
    this.isTemplatePickerShown = true;
  }

  insertTemplate (template: string): void {
    if (this.editorToInsertTemplate === EditorContentType.BUNDLE_SUB_CONTENT) {
      this.currentExerciseBundle!.subContent = template;
    } else if (this.editorToInsertTemplate === EditorContentType.ADDITIONAL_CONTENT) {
      this.exercise.additionalContent!.content = template;
    } else {
      this.currentExerciseVariant.content = template;
    }
  }

  copyContentToSolution (): void {
    this.currentExerciseVariant.solution = this.currentExerciseVariant.content;
  }

  removeAnswer (index: number, answers: ExerciseAnswer[], isAssignmentAnswers = false): void {
    if (answers) answers.splice(index, 1);
    if (this.answersType === AnswersType.ASSIGNMENT && !isAssignmentAnswers) this.currentExerciseVariant.additionalAnswers?.forEach(answer => { answer.correctAnswers = []; });
  }

  addAnswer (answer: ExerciseAnswer | ExerciseAnswerAdditional = this.emptyAnswer, answers: ExerciseAnswer[]): void {
    this.shouldFocus = true;
    const isAnswerAddPossible =
      answers &&
      answers.length < this.getMaxAnswersLength(this.answersType) &&
      this.isCurrentVariantEditable;
    if (isAnswerAddPossible) answers!.push({ ...answer });
  }

  addHint (): void {
    this.currentExerciseVariant.hints?.push({ ...this.emptyHint });
  }

  removeHint (index: number): void {
    this.currentExerciseVariant.hints?.splice(index, 1);
  }

  moveAnswer (payload: ExerciseAnswerMovePayload) {
    if (payload.up) {
      this.moveAnswerUp(payload.answerIndex, payload.answers!);
    } else {
      this.moveAnswerDown(payload.answerIndex, payload.answers!);
    }
  }

  moveAnswerUp (answerIndex: number, answers: ExerciseAnswer[]) {
    if (answerIndex !== 0) {
      swap(answers, answerIndex, answerIndex - 1);
    }
  }

  moveAnswerDown (answerIndex: number, answers: ExerciseAnswer[]) {
    const lastIndex = (answers?.length ?? 0) - 1;
    if (answerIndex !== lastIndex) {
      swap(answers, answerIndex, answerIndex + 1);
    }
  }

  setCorrectAnswer (currentIndex: number, answerIndex: number, answers: ExerciseAnswer[]): boolean {
    const isCurrentElement = answerIndex === currentIndex;
    if (this.answersType === AnswersType.MULTIPLE_CHOICE) {
      return isCurrentElement ? !answers[currentIndex].isCorrect : answers[currentIndex].isCorrect;
    }
    return isCurrentElement;
  }

  selectExerciseAnswer (indexToChange: number, answers: ExerciseAnswer[] | null) {
    answers?.forEach((answer, index) => {
      answer.isCorrect = this.setCorrectAnswer(index, indexToChange, answers as ExerciseAnswer[]);
    });
  }

  selectBundleAnswer (index: number) {
    this.currentExerciseBundle!.answers = this.getSelectedAnswer(index, this.currentExerciseBundle!.answers!);
  }

  getSelectedAnswer (index: number, answers: ExerciseAnswer[] | null): ExerciseAnswer[] | null {
    if (answers) {
      const newAnswers = [];
      for (let i = 0; i < answers.length; i++) {
        newAnswers.push({
          ...answers[i],
          isCorrect: this.setCorrectAnswer(i, index, answers)
        });
      }
      return [...newAnswers];
    }
    return null;
  }

  selectBooleanAnswer (data: { index: number, booleanAnswer: number }): void {
    const answers = this.currentExerciseVariant.answers;
    if (answers) {
      const newAnswers = [];
      for (let i = 0; i < answers.length; i++) {
        newAnswers.push({
          ...answers[i]
        });
      }
      newAnswers[data.index].booleanAnswer = data.booleanAnswer;
      this.currentExerciseVariant.answers = [...newAnswers];
    }
  }

  onAnswerInsert (content: string): void {
    const answers = [AnswersType.BUNDLES, AnswersType.BUNDLES_EXAM].includes(this.answersType as AnswersType) ? this.currentExerciseBundle!.answers : this.currentExerciseVariant.answers;
    if (answers && content) {
      const firstEmptyAnswer = answers.find(el => el.content === '');
      if (firstEmptyAnswer) {
        firstEmptyAnswer.content = content;
      } else {
        this.addAnswer({ ...this.emptyAnswer, content }, answers);
      }
    }
  }

  onAnswerTemplateChange (index: number): void {
    this.exercise.answersTemplate = index;
    this.updateExerciseHeightInEachVariant();
  }

  openDeleteSolutionModal (): void {
    this.isOldAnswerKeyRemoveModalShown = true;
  }

  deleteSolution (): void {
    this.isOldAnswerKeyRemoveModalShown = false;
    this.currentExerciseVariant.solution = '';
  }

  selectEnglishTemplate (id: number): void {
    this.englishTemplate = id;
    this.exercise.englishTemplate = id;
    this.exercise.variants.forEach(variant => {
      variant.answers?.forEach(answer => {
        if (this.exercise.englishTemplate === 0 && answer.booleanAnswer === BooleanAnswerType.DS) {
          answer.booleanAnswer = null;
        }
      });
    });
  }

  addBundle (): void {
    const newBundle: Bundle = deepClone(this.currentExerciseVariant.bundles![this.bundleIndex]);
    newBundle.answers!.forEach(el => { delete el.id; });
    delete newBundle.id;
    this.currentExerciseVariant.bundles!.push(newBundle);
    this.bundleIndex = this.currentExerciseVariant.bundles!.length - 1;
  }

  removeBundle (index: number): void {
    this.bundleIndex = 0;
    this.currentExerciseVariant.bundles!.splice(index, 1);
  }

  changeBundle (index: number): void {
    this.bundleIndex = index;
  }

  isBundleAddBtnVisible (): boolean {
    return !this.isExerciseChangeDisabled;
  }

  isRemoveBundleBtnVisible (): boolean {
    return this.currentExerciseVariant!.bundles!.length > 1 && !this.isExerciseChangeDisabled;
  }

  isTemplatePickerVisible (): boolean {
    return !this.isExerciseOldType && ![
      this.answerTypeEnum.BOOLEAN,
      this.answerTypeEnum.BUNDLES,
      this.answerTypeEnum.BUNDLES_EXAM,
      this.answerTypeEnum.JUSTIFICATION,
      this.answerTypeEnum.FLASHCARD
    ].includes(this.answersType);
  }

  getMaxAnswersLength (answersType: AnswersType): number {
    if (answersType === AnswersType.ASSIGNMENT) {
      return 10;
    } else if (answersType === AnswersType.JUSTIFICATION) {
      return 4;
    }
    return 10;
  }

  get isHintsVisible (): boolean {
    return this.isSuperUser && this.isLoaded && this.answersType !== this.answerTypeEnum.BUNDLES_EXAM && !this.isRedactorNsml
  }

  get isExplanationVisible (): boolean {
    return this.isSuperUser && this.isLoaded && this.answersType !== this.answerTypeEnum.BUNDLES_EXAM
  }

  @Emit()
  onTemplatePreviewOpen (val: boolean): boolean {
    return val;
  }
}
