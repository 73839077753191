
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton
} from '@/shared/components';

@Component({
  name: 'ConfirmationModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton
  }
})
export default class ExerciseVariantRemoveModal extends Vue {
  @Prop() value: boolean;
  @Prop() bodyTextHtml: string;
  @Prop({ default: 'Anuluj' }) closeBtnTxt: string;
  @Prop({ default: 'Usuń' }) removeBtnTxt: string;

  @Emit()
  input (value: boolean): boolean {
    return value;
  }

  close (): void {
    this.input(false);
  }

  @Emit()
  remove (): void {
    this.close();
  }
}
