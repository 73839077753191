import { ExerciseCreateData } from '@/models/exercises';
import { createVariantFactory } from '@/mocks/factories/createVariantMock';

export const emptyExercise: ExerciseCreateData = {
  additionalContent: {
    source: '',
    content: ''
  },
  answersTemplate: null,
  answersType: null,
  englishTemplate: 0,
  bundles: null,
  categoryId: null,
  categoryImageId: null,
  comment: { type: 'TinyMceGenerator', label: null, required: false, description: null, value: null },
  copyright: null,
  earlySchoolEducation: null,
  exerciseType: {
    1: 'zamknięte',
    2: 'otwarte',
    3: 'otwarto-zamknięte',
    4: 'jednokrotny wybór',
    5: 'wielokrotny wybór',
    6: 'prawda‐fałsz',
    7: 'wiązka jednokrotny wybór',
    8: 'na uzasadnianie',
    9: 'przyporządkowanie',
    10: 'fiszka',
    11: 'wiązka zadanie maturalne',
  },
  editor: null,
  exerciseInTests: [],
  isAnswerSpace: false,
  level: { type: 'Radio', label: null, required: false, description: null, value: null },
  newFlagDays: { type: 'Text', label: null, required: true, description: 'liczba dni', value: 0 },
  requirements: { type: 'TinyMceGenerator', label: null, required: false, description: null, value: null },
  score: { type: 'Text', label: null, required: true, description: null, value: null },
  section: { type: 'Select', label: null, required: true, description: null, value: null },
  skills: { type: 'MultiCheckbox', label: null, required: false, description: null, value: [] },
  source: { type: 'Radio', label: null, required: true, description: null, value: null },
  time: { type: 'Text', label: null, required: true, description: null, value: null },
  title: { type: 'Text', label: null, required: false, description: null, value: null },
  variants: [createVariantFactory()]
};
