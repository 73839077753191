
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { SelectItem } from '@ne/ne-vue2-lib/types/components/NeSelect/models';
import { ExerciseImageInsertModalTile } from '../ExerciseImageViewer/ExerciseImageInsertModalTile';
import { ExerciseImageInsertModalAddImage } from '../ExerciseImageViewer/ExerciseImageInsertModalAddImage';
import {
  NeButton,
  NeSimpleGrid,
  NeSimpleGridItem,
  NeSelect,
  NeInfiniteScroll
} from '@ne/ne-vue2-lib';
import { Category, ImageFile } from '@/models/category-images';
import { IMAGES_LISTS, IMAGES_REMOVE } from '@/api/eob/endpoints';
import { sortItems } from '../helpers/sortItem';
import { SortItemIdType } from '../models';

const enum InifiniteScrollState {
  LOADING = 'loading',
  LOADED = 'loaded',
  COMPLETE = 'complete',
  ERROR = 'error'
}

@Component({
  name: 'ExerciseImageViewer',
  components: {
    NeSimpleGrid,
    NeSimpleGridItem,
    NeSelect,
    NeButton,
    ExerciseImageInsertModalTile,
    ExerciseImageInsertModalAddImage,
    NeInfiniteScroll
  }
})

export default class ExerciseImageViewer extends Vue {
  @Prop() isInsertImageModalOpen: boolean;
  @Prop() toggleView: () => {};
  @Prop() categoryImageId: number;
  @Prop() categories: Category[];

  selectedCategoryImageId: number | null = null;
  images: ImageFile[] = [];
  imagesFeed: ImageFile[] = [];
  selectedSortItemId: SortItemIdType = 'dateDesc';
  sortItems = sortItems;
  infinitescrollState = InifiniteScrollState.LOADING;
  infinitescrollId = 0;
  dataChunkLength = 12;

  get mappedCategories (): SelectItem[] {
    return this.categories.map((el) => { return { id: el.id, text: `${el.name} (${el.count})` }; });
  }

  get isLodingComplete (): boolean {
    return this.infinitescrollState === InifiniteScrollState.COMPLETE;
  }

  get isCategorySelectDisabled (): boolean {
    return this.categories.length <= 1;
  }

  mounted () {
    this.setSelectedCategoryImageIdOnMount();
    this.getImages(this.selectedCategoryImageId);
  }

  setSelectedCategoryImageIdOnMount (): void {
    const filledCategory: Category | undefined = this.categories.find(category => category.count > 0);
    this.selectedCategoryImageId = filledCategory?.id ?? this.categories[0].id;
  }

  handleErrorRemoveResponse (e: XMLHttpRequest) {
    // TODO error response to handle
    console.log(e);
  }

  async removeImage (id: number): Promise<void> {
    try {
      await this.$api.delete(IMAGES_REMOVE(id));
      this.imageRemoved();
    } catch (e) {
      this.handleErrorRemoveResponse(e);
    }
  }

  async getImages (categoryId: number | null): Promise<ImageFile[]> {
    this.imagesFeed = [];
    this.infinitescrollId++;
    this.infinitescrollState = InifiniteScrollState.LOADING;
    const response = (await this.$api.get<any>(IMAGES_LISTS(categoryId, this.selectedSortItemId))).data;
    this.images = response || [];
    this.infinitescrollState = InifiniteScrollState.LOADED;
    return response;
  }

  getInfiniteScrollState (): InifiniteScrollState {
    return (this.images.length === this.imagesFeed.length) ? InifiniteScrollState.COMPLETE : InifiniteScrollState.LOADED;
  }

  getImagesDataChunk (): void {
    this.infinitescrollState = InifiniteScrollState.LOADING;
    const startId = this.imagesFeed.length;
    const endId = this.imagesFeed.length + this.dataChunkLength;
    this.imagesFeed.push(...this.images.slice(startId, endId));
    this.$nextTick(() => {
      this.infinitescrollState = this.getInfiniteScrollState();
    });
  }

  onSelectedCategoryImageChange (categoryId: number): void {
    this.selectedCategoryChanged(categoryId);
    this.getImages(this.selectedCategoryImageId);
  }

  onSelectedOrderChange (id: SortItemIdType): void {
    this.selectedSortItemId = id;
    this.getImages(this.selectedCategoryImageId);
  }

  @Emit()
  selectedCategoryChanged (id: number) {
    this.selectedCategoryImageId = id;
    return id;
  }

  @Emit() onInsertImage (imageSrc: { src: string, title: string}): { src: string, title: string} {
    return { src: imageSrc.src, title: imageSrc.title };
  }

  @Emit()
  imageRemoved (): void {}
}

