var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"gt-exercise-answers-item",class:{
    'gt-exercise-answers-item--boolean': _vm.type === _vm.Type.BOOLEAN,
    'gt-exercise-answers-item--assignment': _vm.isAssignment,
    'gt-exercise-answers-item--no-margin-left' : !_vm.radioButtonsVisible
  }},[(_vm.radioButtonsVisible)?_c('div',{staticClass:"gt-exercise-answers-item__value-wrapper",class:{
      'gt-exercise-answers-item__value-wrapper--disabled': _vm.isCurrentVariantDisabled,
      'gt-exercise-answers-item__value-wrapper--multiselect': _vm.isAssignment,
      'gt-exercise-answers-item__value-wrapper--single-choice': _vm.type === _vm.Type.BOOLEAN,
    },on:{"click":[_vm.selectAnswer,function($event){$event.stopPropagation();$event.preventDefault();_vm.type === _vm.Type.BOOLEAN || _vm.type === _vm.Type.MULTIPLE_CHOICE}]}},[(_vm.type !== _vm.Type.BOOLEAN)?_c('div',{staticClass:"gt-exercise-answers-item__value-group"},[_c('strong',[_vm._v(_vm._s(_vm.isNumericAnswer ? _vm.index + 1 : _vm.group)+".")])]):_vm._e(),(_vm.isRadioButtonsVisible)?_c('div',{staticClass:"gt-exercise-answers-item__value"},[(_vm.type === _vm.Type.SINGLE_CHOICE || _vm.type === _vm.Type.JUSTIFICATION)?_c('NeRadioButton',{attrs:{"id":_vm.index,"color":"primary","group-name":_vm.radioGroupName,"name":_vm.index,"value":_vm.correctAnswerIndex}}):_vm._e(),(_vm.type === _vm.Type.MULTIPLE_CHOICE)?_c('NeCheckbox',{attrs:{"id":_vm.index,"value":_vm.answer.isCorrect,"color":"primary"}}):(_vm.type === _vm.Type.BOOLEAN)?_c('BooleanExerciseAnswers',{attrs:{"answer":_vm.answer,"group":_vm.group,"index":_vm.index,"english-template":_vm.englishTemplate,"is-english":_vm.isEnglish,"editor-mode":_vm.editorMode,"type":_vm.type},on:{"select-boolean-answer":_vm.selectBooleanAnswer}}):_vm._e(),(_vm.isAssignment && _vm.multiselectItems)?_c('NeMultiselect',{staticClass:"gt-exercise-answers-item__multiselect",attrs:{"bordered":"","rounded-small":"","is-row-layout":true,"items":_vm.multiselectItems,"placeholder":_vm.$t('GT_MENU.select').toLocaleLowerCase(),"selection-bg-color":'primary',"hover-bg-color":'green-lighten-4',"arrow-color":'black-lighten-2'}}):_vm._e()],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"gt-text-editor",class:{
      'gt-text-editor--correct': _vm.isCorrect,
      'gt-text-editor--invalid': _vm.isInvalid
    }},[(_vm.isRadioButtonsVisible)?_c('TinyMceEditor',{ref:"editor",attrs:{"id":_vm.editorId,"init":_vm.editorInit},on:{"onInit":_vm.onEditorInit},model:{value:(_vm.answer.content),callback:function ($$v) {_vm.$set(_vm.answer, "content", $$v)},expression:"answer.content"}}):_vm._e(),(!_vm.isRadioButtonsVisible && !_vm.isExplanation)?_c('TinyMceEditor',{ref:"editor",attrs:{"id":_vm.editorId,"init":_vm.editorInit},on:{"onInit":_vm.onEditorInit},model:{value:(_vm.hint.content),callback:function ($$v) {_vm.$set(_vm.hint, "content", $$v)},expression:"hint.content"}}):_vm._e(),(_vm.isExplanation)?_c('TinyMceEditor',{ref:"editor",attrs:{"id":_vm.editorId,"init":_vm.editorInit},on:{"onInit":_vm.onEditorInit},model:{value:(_vm.currentVariant.explanation),callback:function ($$v) {_vm.$set(_vm.currentVariant, "explanation", $$v)},expression:"currentVariant.explanation"}}):_vm._e()],1),_c('div',[_c('Tooltip',{staticClass:"gt-exercise-answers-item__tooltip",attrs:{"text":_vm.$t('EXERCISE_ANSWER.minimal_length_info'),"disabled":_vm.isRemoveBtnEnabled},scopedSlots:_vm._u([{key:"activator",fn:function(){return [(!_vm.isCurrentVariantDisabled)?_c('NeIconButton',{staticClass:"gt-exercise-answers-item__delete-btn",class:{'gt-exercise-answers-item__delete-btn--disabled': _vm.isCurrentVariantDisabled},attrs:{"icon":"delete","color":"error","disabled":!_vm.isRemoveBtnEnabled,"size":"sm","flat":""},on:{"click":_vm.remove}}):_vm._e()]},proxy:true}])},[[_c('span',{domProps:{"innerHTML":_vm._s(_vm.isExamBundle ? _vm.$t('EXERCISE_ANSWER.minimal_length_info_1') : _vm.$t('EXERCISE_ANSWER.minimal_length_info'))}})]],2)],1),(_vm.isInsertImageModalOpen && _vm.categoryImageId)?_c('ExerciseImageInsertModal',{attrs:{"is-insert-image-modal-open":_vm.isInsertImageModalOpen,"category-image-id":_vm.categoryImageId},on:{"on-insert-image":_vm.insertImage,"close-insert-image-modal":_vm.closeInsertImageModal}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }