
import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton
} from '@/shared/components';

@Component({
  name: 'overwrite-content-modal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton
  }
})
export default class OverwriteContentModal extends Vue {
  @Prop() value: boolean;

  @Emit()
  input (value: boolean): boolean {
    return value;
  }

  close (): void {
    this.input(false);
  }

  @Emit()
  insert (): void {
    this.close();
  }
}
