
import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import {
  Modal,
  ModalHeader,
  ModalBody
} from '@/shared/components';
import {
  NeTextField,
  NeButton,
  NeRadioButton
} from '@ne/ne-vue2-lib';
import {
  CustomAnswerFieldTypes,
  FieldType
} from './../models';

const defaultColumnsAmount = 1;
const defaultRowsAmount = 1;

@Component({
  name: 'ExerciseAnswerFieldModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    NeTextField,
    NeButton,
    NeRadioButton
  }
})
export default class ExerciseAnswerFieldModal extends Vue {
  @Prop() value: boolean;
  @Prop() customAnswerFieldTypes: CustomAnswerFieldTypes[];

  columnsAmount: number | string = defaultColumnsAmount;
  rowsAmount: number | string = defaultRowsAmount;
  tmpColumnsAmount = defaultColumnsAmount;
  tmpRowsAmount = defaultRowsAmount;
  maxColumnsAmount = 35;
  maxRowsAmount = 20;
  selectedFieldType: string = '';

  get htmlOutput (): string {
    const cell = `<span class="answer-field__td answer-field__td--${this.selectedFieldType}" ></span>`;
    const row = `<span class="answer-field__tr answer-field__tr--${this.selectedFieldType}">${[...Array(this.columnsAmount)].map(() => cell).join('')}</span>`;
    const body = `<span class="answer-field__tbody answer-field__tbody--${this.selectedFieldType}">${[...Array(this.rowsAmount)].map(() => row).join('')}</span>`;
    const table = `<span class="answer-field__table answer-field__table--${this.selectedFieldType} mceNonEditable">${body}</span>`;
    return `<span class="answer-field">&ZeroWidthSpace;${table}</span> `; // space after span sets correct caret position and prevents from writting in it
  }

  mounted (): void {
    this.selectedFieldType = (this.customAnswerFieldTypes && this.customAnswerFieldTypes[0]?.name) ?? '';
  }

  tableCellClasses (rowNumber: number, columnNumber: number): any {
    const modificator = this.selectedFieldType === FieldType.MULTILINE ? `${this.selectedFieldType}-${(rowNumber - 1) % 3}` : this.selectedFieldType;
    return [
      `gt-exercise-answer-field-modal__table-cell--${modificator}`,
      {
        'gt-exercise-answer-field-modal__table-cell--hovered': rowNumber <= this.tmpRowsAmount && columnNumber <= this.tmpColumnsAmount
      }
    ];
  }

  setTmpSize (rowNumber: number | string, columnNumber: number | string): void {
    this.tmpRowsAmount = parseInt(rowNumber.toString());
    this.tmpColumnsAmount = parseInt(columnNumber.toString());
  }

  setSize (rowNumber: number, columnNumber: number): void {
    this.rowsAmount = rowNumber;
    this.columnsAmount = columnNumber;
    this.createChecker();
  }

  @Emit()
  input (value: boolean): boolean {
    return value;
  }

  close (): void {
    this.input(false);
    this.selectedFieldType = (this.customAnswerFieldTypes && this.customAnswerFieldTypes[0]?.name) ?? '';
  }

  @Emit()
  insert (): string {
    return this.htmlOutput;
  }

  createChecker () {
    this.insert();
    this.close();
    this.rowsAmount = defaultRowsAmount;
    this.columnsAmount = defaultColumnsAmount;
  }
}
