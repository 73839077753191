
import { Component, Emit, Vue, Prop } from 'vue-property-decorator';
import {
  NeSpinner,
  NeButton,
  NeAppWrapper
} from '@ne/ne-vue2-lib';

@Component({
  name: 'ExerciseFooter',
  components: {
    NeSpinner,
    NeButton,
    NeAppWrapper
  }
})
export default class ExerciseFooter extends Vue {
  @Prop({ default: false }) showDeleteBtn: boolean;
  @Prop() isSavingCompleted: boolean;
  @Prop() currentExerciseTypeName: string | null;
  @Prop() isExerciseOld: boolean | null;

  @Emit() cancel (): void {}
  @Emit() showDeleteModal (): void {}
  @Emit() save (): void {}
  @Emit() saveAndClose (): void {}
  @Emit() openTemplatePreview (): void {}
}
