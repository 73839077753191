
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { NeButton } from '@ne/ne-vue2-lib';

@Component({
  name: 'ExerciseAccordion',
  components: {
    NeButton
  }
})
export default class ExerciseAccordion extends Vue {
  @Prop({ default: false }) value!: boolean;

  get isCollapsed (): boolean {
    return !this.value;
  }

  set isCollapsed (isCollapsed: boolean) {
    this.input();
  }

  get toggleText (): TranslateResult {
    return this.isCollapsed ? this.$tc('EXERCISE_COMPOSER.expand') : this.$tc('EXERCISE_COMPOSER.collapse');
  }

  @Emit() input (): boolean {
    return this.isCollapsed;
  }

  toggleContent (): void {
    this.isCollapsed = !this.isCollapsed;
  }
}
