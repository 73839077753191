
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  EditorModeName, PluginLocation, PluginName
} from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/models';
import { NeButton, NeIconButton } from '@ne/ne-vue2-lib';
import { Variant } from '@/models/exercises';
import { resourcePath } from '@/config';
import { advancedPluginLocations } from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/helpers/plugins';
import {
  exerciseAnswerSubjectButtons,
  exerciseAnswerSubjectPlugins
} from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/helpers/answers-subject-buttons';
import TinyMceEditor from '@tinymce/tinymce-vue';
import ExerciseAnswersItem
  from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseAnswers/ExerciseAnswersItem';

@Component({
  name: 'ExerciseExplanation',
  components: { ExerciseAnswersItem, NeButton, NeIconButton, TinyMceEditor }
})
export default class ExerciseExplanation extends Vue {
  @Prop({ default: 'sel-add-hint-btn-0' }) addBtnId: string;
  @Prop() isExerciseChangeDisabled: boolean;
  @Prop() isCurrentVariantDisabled: boolean;
  @Prop() categoryImageId: number;
  @Prop() isEarlySchoolEducation: boolean;
  @Prop() mode: EditorModeName;
  @Prop() currentVariant: Variant;

  editorInstance: any;
  isInsertImageModalOpen = false;
  isLoaded = false;
  objectId: number = Math.floor(Math.random() * 100000000);
  isExplanationVisible = false;

  get activeAdvancedPlugins (): PluginName[] {
    return exerciseAnswerSubjectPlugins[this.mode];
  }

  get filteredAdvancedPluginLocations (): PluginLocation[] {
    return advancedPluginLocations.filter(plugin => this.activeAdvancedPlugins.includes(plugin.name));
  }

  get externalPlugins (): any {
    const externalPlugins: {[key: string]: string} = {};
    this.filteredAdvancedPluginLocations.forEach(plugin => {
      externalPlugins[plugin.name] = `${resourcePath}${plugin.path}`;
    });
    return externalPlugins;
  }

  get toolbarButtons (): string {
    const defaultButtons = 'undo redo bold italic ';
    const rightSideButtons = ' | moveAnswerUp moveAnswerDown';
    const subjectButtons = exerciseAnswerSubjectButtons[this.mode] ?? '';
    return defaultButtons.concat(subjectButtons, rightSideButtons);
  }

  addExplanation (): void {
    this.isExplanationVisible = true;
  }

  removeExplanation (): void {
    this.currentVariant.explanation = '';
    this.isExplanationVisible = false;
  }
}
