import {
  PluginLocation,
  SubjectPlugins
} from '../models';

export const advancedPluginLocations: PluginLocation[] = [
  {
    name: 'mathlive',
    // path: 'assets/tinymce/plugins/mathlive/plugin.js'
    path: ''
  }
];

export const subjectPlugins: SubjectPlugins = {
  default: [],
  angielski_sp1_3: ['charmap'],
  angielski_sp: ['charmap'],
  angielski_spp: ['charmap'],
  biologia_sp: ['mathlive'],
  biologia_spp: ['mathlive'],
  chemia_sp: ['mathlive'],
  chemia_spp: ['mathlive'],
  edb_sp: ['charmap'],
  edb_spp: ['charmap'],
  fizyka_sp: ['mathlive'],
  fizyka_spp: ['mathlive'],
  geografia_sp: ['charmap'],
  geografia_spp: ['charmap'],
  historia_sp: ['charmap'],
  historia_spp: ['charmap'],
  matematyka_sp1_3: ['charmap'],
  matematyka_sp: ['mathlive'],
  matematyka_spp: ['mathlive'],
  niemiecki_sp: ['charmap'],
  niemiecki_spp: ['charmap'],
  polski_sp: ['charmap'],
  polski_spp: ['charmap'],
  przedsiebiorczosc_spp: ['charmap'],
  przyroda_sp: [],
  przyroda_spp: [],
  technika_sp: ['charmap'],
  wos_sp: ['charmap'],
  wos_spp: ['charmap']
};
