
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { NeFileUploadTile, NeButton, NeSpinner, NeIcon, NeSelect, NeTextField, NeCheckbox } from '@ne/ne-vue2-lib';
import { ExerciseImageUploaderPreview } from './ExerciseImageUploaderPreview';
import { IMAGE_SAVE } from '@/api/eob/endpoints';
import { Category } from '@/models/category-images';
import ImageProcessor
  from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/ExerciseImageInsertModal/ExerciseImageUploader/helpers/ImageProcessor';

@Component({
  name: 'ExerciseImageUploader',
  components: { NeFileUploadTile, NeButton, NeSpinner, NeIcon, NeSelect, NeTextField, NeCheckbox, ExerciseImageUploaderPreview }
})
export default class ExerciseImageUploader extends Vue {
  @Prop() toggleView: (showNewestImages?: boolean) => {};
  @Prop() categoryImageId: number;
  @Prop() categories: Category[];
  selectedCategoryImageId: number | null = null;
  imageProcessor: ImageProcessor | null = null;
  url = '';
  isLoading = false;
  imageName: string = '';
  originalName: string = '';
  acceptedFileExtensions: string[] = [
    '.jpg',
    '.jpeg',
    '.png'
  ]

  async save (): Promise<void> {
    this.isLoading = true;
    const formData: FormData = new FormData();
    formData.append('categoryId', this.categoryImageId.toString());
    formData.append('name', this.imageName);
    formData.append('file', (this.imageProcessor as ImageProcessor).dataURLtoFile(this.url, this.originalName));
    await this.$api.post(IMAGE_SAVE, formData);
    this.toggleView(true);
    this.isLoading = false;
    this.imageUploaded();
  }

  async previewImage (image: File): Promise<void> {
    const url = URL.createObjectURL(image);
    this.imageName = this.removeExtension(image.name);
    this.originalName = `${this.removeExtension(image.name)}.jpeg`;
    this.imageProcessor = new ImageProcessor();
    this.url = await this.imageProcessor.initProcessor(url);
  }

  @Emit() closeModal () {}

  @Emit()
  selectedCategoryChanged (id: number) {
    this.selectedCategoryImageId = id;
    return id;
  }

  @Emit() imageUploaded (): void {}

  removeImage (): void {
    this.url = '';
  }

  nameChanged (name: string): void {
    this.imageName = name;
  }

  removeExtension (name: string): string {
    const imageNameArr = name.split('.');
    if (imageNameArr.length > 1) { imageNameArr.pop(); }
    return imageNameArr.join('');
  }

  async parseToBW (blackAndWhite: boolean): Promise<void> {
    this.url = await (this.imageProcessor as ImageProcessor).toggleBnW(blackAndWhite);
  }
}
