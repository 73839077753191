import { SubjectButtons, SubjectPlugins } from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/models';

export const exerciseAnswerSubjectButtons: SubjectButtons = {
  default: '',
  angielski_sp: 'subscript superscript charmap insert-image nonbreaking',
  angielski_sp1_3: 'fontsize charmap insert-image nonbreaking',
  angielski_spp: 'subscript superscript charmap insert-image nonbreaking',
  biologia_sp: 'subscript superscript mathlive insert-image nonbreaking',
  biologia_spp: 'subscript superscript mathlive insert-image nonbreaking',
  chemia_sp: 'subscript superscript mathlive insert-image',
  chemia_spp: 'subscript superscript mathlive insert-image',
  edb_sp: 'subscript superscript charmap insert-image nonbreaking',
  edb_spp: 'subscript superscript charmap insert-image nonbreaking',
  fizyka_sp: 'subscript superscript mathlive insert-image',
  fizyka_spp: 'subscript superscript mathlive insert-image',
  geografia_sp: 'subscript superscript charmap insert-image nonbreaking',
  geografia_spp: 'subscript superscript charmap insert-image nonbreaking',
  historia_sp: 'subscript superscript charmap insert-image nonbreaking',
  historia_spp: 'subscript superscript charmap insert-image nonbreaking',
  matematyka_sp: 'subscript superscript mathlive insert-image nonbreaking',
  matematyka_sp1_3: 'fontsize charmap insert-image nonbreaking',
  matematyka_spp: 'subscript superscript mathlive insert-image nonbreaking',
  niemiecki_sp: 'subscript superscript charmap insert-image nonbreaking',
  niemiecki_spp: 'subscript superscript charmap insert-image nonbreaking',
  polski_sp: 'subscript superscript charmap insert-image nonbreaking',
  polski_spp: 'subscript superscript charmap insert-image nonbreaking',
  przedsiebiorczosc_spp: 'subscript superscript charmap insert-image nonbreaking',
  przyroda_sp: 'subscript superscript insert-image nonbreaking',
  przyroda_spp: 'subscript superscript charmap insert-image nonbreaking',
  technika_sp: 'subscript superscript charmap insert-image nonbreaking',
  wos_sp: 'subscript superscript charmap insert-image nonbreaking',
  wos_spp: 'subscript superscript charmap insert-image nonbreaking'
};

export const exerciseAnswerSubjectPlugins: SubjectPlugins = {
  default: [],
  angielski_sp1_3: ['charmap'],
  angielski_sp: ['charmap'],
  angielski_spp: ['charmap'],
  biologia_sp: ['mathlive'],
  biologia_spp: ['mathlive'],
  chemia_sp: ['mathlive'],
  chemia_spp: ['mathlive'],
  edb_sp: ['charmap'],
  edb_spp: ['charmap'],
  fizyka_sp: ['mathlive'],
  fizyka_spp: ['mathlive'],
  geografia_sp: ['charmap'],
  geografia_spp: ['charmap'],
  historia_sp: ['charmap'],
  historia_spp: ['charmap'],
  matematyka_sp1_3: ['charmap'],
  matematyka_sp: ['mathlive'],
  matematyka_spp: ['mathlive'],
  niemiecki_sp: ['charmap'],
  niemiecki_spp: ['charmap'],
  polski_sp: ['charmap'],
  polski_spp: ['charmap'],
  przedsiebiorczosc_spp: ['charmap'],
  przyroda_sp: [],
  przyroda_spp: ['charmap'],
  technika_sp: ['charmap'],
  wos_sp: ['charmap'],
  wos_spp: ['charmap']
};
