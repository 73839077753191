
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ExerciseConfigurationSection from '../ExerciseConfigurationSection';
import ExerciseAccordion from '../ExerciseAccordion';
import ExerciseAdminControl from './ExerciseAdminControl';
import ExerciseConfigurationEditor from './ExerciseConfigurationEditor';
import { NeCheckbox, NeRadioButton } from '@ne/ne-vue2-lib';
import { Autocomplete, AutocompleteItem, Panel, PanelBody, PanelHeader, TextField, Tooltip } from '@/shared/components';
import {
  AnswersType,
  ExerciseCreateData,
  ExerciseFieldOption,
  ExerciseSourceOption,
  SelectedOption
} from '@/models/exercises';
import { IS_ADMIN } from '@/store/list';
import { Active } from '@/config';
import { exerciseLevel, exerciseLevels } from '@/helpers/exercise';
import { fulfillWordsWithCommas } from '@/helpers';

const AppStore = namespace('AppStore');

@Component({
  name: 'ExerciseConfiguration',
  components: {
    Panel,
    PanelHeader,
    PanelBody,
    ExerciseConfigurationSection,
    ExerciseAdminControl,
    ExerciseConfigurationEditor,
    NeCheckbox,
    NeRadioButton,
    Autocomplete,
    ExerciseAccordion,
    TextField,
    Tooltip
  }
})
export default class ExerciseConfiguration extends Vue {
  @Prop() exercise: ExerciseCreateData;
  @Prop() initialStatus: Active;
  @Prop() isEditable: boolean;
  @Prop({ default: '' }) scoreTooltipValue: boolean;
  @Prop() isExerciseOldType: boolean;
  @Prop() isLoaded: boolean;
  @Prop() isSuperUser: boolean;
  @Prop() isPublisher: boolean;
  @Prop() isValidationActive: boolean;
  @Prop() stringifiedErrors: string;
  @Prop({ default: true }) isScoreFormEnabled: boolean;

  isPanelExpanded = false;
  selectedTypes: number[] = [];
  isSectionModified = false;
  isTypeModified = false;
  isScoreModified = false;
  isRangeModified = false;
  isNewFlagDaysModified = false;
  isTimeInMinutesModified = false;
  isStatusChecked = false;

  @AppStore.Getter(IS_ADMIN) isAdmin: any;

  getAdditionalOptionsLabel (isSkillVisible: boolean, isLevelVisible: boolean, isCommentVisible: boolean, isRequiremntsVisible: boolean = true): string {
    const wordsArr: string[] = [];
    if (isSkillVisible) wordsArr.push(this.$tc('EXERCISE_COMPOSER.add_advanced_options_skills'));
    if (isLevelVisible) wordsArr.push(this.$tc('EXERCISE_COMPOSER.add_advanced_options_level'));
    if (isCommentVisible) wordsArr.push(this.$tc('EXERCISE_COMPOSER.add_advanced_options_comment'));
    if (isRequiremntsVisible) wordsArr.push(this.$tc('EXERCISE_COMPOSER.add_advanced_options_requirements'));
    return fulfillWordsWithCommas(wordsArr);
  }

  get isCommentVisible (): boolean {
    return this.isExerciseOldType || this.isSuperUser;
  }

  get isSkillsVisible (): boolean {
    if (this.isEditable) return this.exerciseSkills.length > 0;
    return this.selectedSkills.length > 0;
  }

  get isLevelVisible (): boolean {
    if (this.isEditable) return this.exercise.level !== null;
    if (this.exercise.level) return this.exercise.level.value !== null;
    return this.exercise.level !== null;
  }

  get assessmentCriteriaKey (): number {
    if (this.exercise.answersType === AnswersType.ASSIGNMENT) return this.exercise.score.value! + this.exercise.variants[0].additionalAnswers!.length;
    return this.exercise.score.value! + (this.exercise.variants[0].answers?.length ?? 0);
  }

  get sectionLabel (): string {
    let label = '';
    const value = this.exercise.section.value;
    if (value) {
      const findLabel = (items: ExerciseFieldOption[], path: string[]): string[] => {
        let rPath: string[] = [];
        for (const item of items) {
          let tmpPath: string[] = [];
          if (item.id === value) {
            tmpPath.push(item.name || '');
          } else if (item.children) {
            tmpPath = findLabel(item.children, [...path, item.name || '']);
          }
          if (tmpPath.length > 0) {
            rPath = [...path, ...tmpPath];
            break;
          }
        }
        return rPath;
      };
      label = findLabel(this.exercise.section.options || [], []).join(' ➡ ');
    }
    return label;
  }

  get levelLabel (): string {
    return exerciseLevel(this.exerciseLevels, this.exercise);
  }

  get rangeLabel (): string {
    let label = '';
    if (this.exercise.range) {
      const value = this.exercise.range.value;
      const options = this.exerciseRanges;
      const option = options.find(option => value === option.id);
      if (option) label = option.label || '';
    }
    return label;
  }

  get isStatusChangeEnabled (): boolean {
    return this.initialStatus === Active.PUBLISHED ? this.isAdmin : true;
  }

  get statusLabel (): string {
    let statusLabel = '';
    if (this.initialStatus === Active.PUBLISHED) {
      statusLabel = 'PUBLISHED';
    } else if (this.initialStatus === Active.DRAFT) {
      statusLabel = 'READY_TO_BE_PUBLISHED';
    } else if (this.initialStatus === Active.READY_TO_BE_PUBLISHED) {
      statusLabel = this.isPublisher ? 'PUBLISHED' : 'READY_TO_BE_PUBLISHED';
    }
    return this.$tc(`ACTIVITY_STATUS.${statusLabel}`);
  }

  get validationErrors (): any[] {
    return JSON.parse(this.stringifiedErrors);
  }

  get exerciseCategories () {
    const categories = this.exercise.section.options || [];
    function categoryItems (items: ExerciseFieldOption[], highlight: boolean): AutocompleteItem[] {
      const rItems: AutocompleteItem[] = [];
      for (const item of items) {
        const rItem: AutocompleteItem = {
          value: item.id,
          label: item.name || '',
          highlighted: highlight
        };
        if (item.children) {
          const childItems = categoryItems(item.children, false);
          rItem.children = childItems;
        }
        rItems.push(rItem);
      }
      return rItems;
    }
    return categoryItems(categories, true);
  }

  get exerciseSkills (): ExerciseFieldOption[] {
    return this.exercise.skills ? this.exercise.skills.options || [] : [];
  }

  get selectedSkills (): number[] {
    return this.exercise.skills ? this.exercise.skills.value || [] : [];
  }

  get exerciseLevels (): ExerciseFieldOption[] {
    return exerciseLevels(this.exercise);
  }

  get exerciseRanges (): ExerciseFieldOption[] {
    return this.exercise.range ? this.exercise.range.options || [] : [];
  }

  get exerciseSources (): ExerciseFieldOption[] {
    return this.exercise.source.options || [];
  }

  get stringifiedSkills (): string {
    const values = this.selectedSkills;
    const options = this.exerciseSkills;
    const labels = options.filter(option => values.includes(option.id)).map(option => option.label);
    return labels.join(', ');
  }

  @Watch('isLoaded')
  handleAdvancedSectionStatus (): void {
    const isAdvancedSectionModified =
      this.selectedSkills.length > 0 ||
      (this.exercise.level && !!this.exercise.level.value) ||
      !!this.exercise.time.value ||
      !!this.exercise.comment.value ||
      !!this.exercise.requirements.value;
    if (isAdvancedSectionModified) this.isPanelExpanded = true;
  }

  @Watch('initialStatus')
  handleStatus (): void {
    this.isStatusChecked =
      ((this.initialStatus === Active.DRAFT || !this.isPublisher) &&
        this.exercise.active === Active.READY_TO_BE_PUBLISHED) ||
      this.exercise.active === Active.PUBLISHED;
  }

  created (): void {
    if (this.isSuperUser) this.isPanelExpanded = true;
  }

  @Emit()
  onChapterAndSelectionChange (chapterAndSectionLabel: string) {
    return chapterAndSectionLabel;
  }

  @Emit()
  onLevelChange (): string {
    return this.levelLabel;
  }

  handleSectionSelect (section: AutocompleteItem): void {
    this.exercise.section.value = section.value;
    this.exercise.section.label = section.label;
    this.onChapterAndSelectionChange(this.sectionLabel);
  }

  handleSourceCheck (source: ExerciseSourceOption) {
    this.exercise.author = source.label;
    this.exercise.source.value = source.id;
    if (this.exercise.variants) {
      for (const variant of this.exercise.variants) {
        variant.author = source.label;
      }
    }
  }

  handleStatusCheck (isChecked: boolean): void {
    let status: Active;
    if (this.initialStatus === Active.DRAFT || !this.isPublisher) {
      status = isChecked ? Active.READY_TO_BE_PUBLISHED : Active.DRAFT;
    } else {
      status = isChecked ? Active.PUBLISHED : Active.READY_TO_BE_PUBLISHED;
    }
    this.exercise.active = status;
  }

  isSkillSelected (skillId: number): boolean {
    return this.selectedSkills.includes(skillId);
  }

  resetLevel (): void {
    if (this.exercise.level) this.exercise.level.value = null;
  }

  handleSkillCheck (isChecked: boolean, skill: SelectedOption): void {
    let exerciseSkills = this.selectedSkills;
    if (isChecked && skill.id) {
      exerciseSkills.push(skill.id);
    } else {
      exerciseSkills = exerciseSkills.filter(tmpType => tmpType !== skill.id);
    }
    if (this.exercise.skills) this.exercise.skills.value = exerciseSkills;
  }
}
