import { AnswerFieldTypesPerSubject, FieldType } from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/models';

export const checker = { label: 'EXERCISE_COMPOSER.answer_field.checker', name: FieldType.CHECKER };
export const line = { label: 'EXERCISE_COMPOSER.answer_field.line', name: FieldType.LINE };
export const multiline = { label: 'EXERCISE_COMPOSER.answer_field.multiline', name: FieldType.MULTILINE };
export const empty = { label: 'EXERCISE_COMPOSER.answer_field.empty', name: FieldType.EMPTY };

export const answerFieldTypesPerSubject: AnswerFieldTypesPerSubject = {
  default: [
    checker,
    line,
    empty
  ],
  angielski_sp: [
    line,
    checker,
    empty
  ],
  angielski_sp1_3: [
    multiline,
    checker,
    empty,
    line
  ],
  angielski_spp: [
    line,
    checker,
    empty
  ],
  biologia_sp: [
    line,
    checker,
    empty
  ],
  biologia_spp: [
    line,
    checker,
    empty
  ],
  chemia_sp: [
    checker,
    line,
    empty
  ],
  chemia_spp: [
    checker,
    line,
    empty
  ],
  edb_sp: [
    line,
    checker,
    empty
  ],
  edb_spp: [line],
  fizyka_sp: [
    checker,
    empty,
    line
  ],
  fizyka_spp: [
    checker,
    empty,
    line
  ],
  geografia_sp: [
    checker,
    line,
    empty
  ],
  geografia_spp: [
    checker,
    line,
    empty
  ],
  historia_spp: [line],
  historia_sp: [line],
  matematyka_sp: [
    checker,
    line,
    empty
  ],
  matematyka_spp: [
    checker,
    line,
    empty
  ],
  matematyka_sp1_3: [
    checker,
    line,
    empty
  ],
  niemiecki_sp: [line],
  niemiecki_spp: [line],
  polski_sp: [
    line,
    checker,
    empty
  ],
  polski_spp: [
    line,
    checker,
    empty
  ],
  przedsiebiorczosc_spp: [line],
  przyroda_sp: [
    line,
    checker,
    empty
  ],
  przyroda_spp: [
    line,
    checker,
    empty
  ],
  technika_sp: [
    line,
    checker,
    empty
  ],
  wos_sp: [
    line,
    checker,
    empty
  ],
  wos_spp: [line]
};
