import { EditorMathliveMode } from '../models';

export const editorMathliveModes: EditorMathliveMode[] = [
  {
    editor: 'biologia_sp',
    mathliveMode: 'biologia_sp'
  },
  {
    editor: 'biologia_spp',
    mathliveMode: 'biologia_spp'
  },
  {
    editor: 'chemia_sp',
    mathliveMode: 'chemia_sp'
  },
  {
    editor: 'chemia_spp',
    mathliveMode: 'chemia_spp'
  },
  {
    editor: 'fizyka_sp',
    mathliveMode: 'fizyka_sp'
  },
  {
    editor: 'fizyka_spp',
    mathliveMode: 'fizyka_spp'
  },
  {
    editor: 'matematyka_sp',
    mathliveMode: 'matematyka_sp'
  },
  {
    editor: 'matematyka_spp',
    mathliveMode: 'matematyka_spp'
  }
];
