
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Modal, ModalBody, ModalHeader } from '@/shared/components';
import { AnswersType, ExerciseCreateData, ExerciseTemplate, Variant } from '@/models/exercises';
import { templates } from './_templates/template-1';
import { NeRadioButton, NeSpinner, NeTile, NeCheckbox } from '@ne/ne-vue2-lib';
import ExercisePreviewContent from './ExercisePreviewContent/ExercisePreviewContent.vue';
import ExerciseVariantPicker from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseVariantPicker';
import ExercisePreviewNavigationButton from '@/shared/components/ExercisePreviewModal/ExercisePreviewNavigationButton';
import { ExercisePreviewTemplateEnum } from './exercise-preview-template.enum';

@Component({
  name: 'ExerciseTemplatePreviewModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ExercisePreviewContent,
    ExerciseVariantPicker,
    ExercisePreviewNavigationButton,
    NeTile,
    NeSpinner,
    NeRadioButton,
    NeCheckbox
  }
})
export default class ExerciseTemplatePreviewModal extends Vue {
  @Prop() answerType: AnswersType;
  @Prop() currentBundleIndex: number;
  @Prop() englishTemplate: number;
  @Prop() exercise: ExerciseCreateData;
  @Prop() isExerciseChangeDisabled: boolean;
  @Prop() isCurrentVariantEditable: boolean;
  @Prop() isSuperUser: boolean;
  @Prop() value: boolean;
  @Prop() variantIndex: number;

  templateEnum = ExercisePreviewTemplateEnum;

  get isTemplatePickerVisible (): boolean {
    if (this.answerType) {
      return [
        AnswersType.SINGLE_CHOICE,
        AnswersType.MULTIPLE_CHOICE,
        AnswersType.ASSIGNMENT
      ].includes(this.exercise.answersType as AnswersType);
    }
    return false;
  }

  get isAdditionalSpaceContainerVisible (): boolean {
    return this.answerType === AnswersType.ASSIGNMENT;
  }

  get currentExerciseVariant (): Variant {
    return this.exercise.variants[this.variantIndex];
  }

  get templates (): ExerciseTemplate[] {
    return templates;
  }

  get template (): ExerciseTemplate {
    return this.templates[this.exercise.answersTemplate!];
  }

  close (): void {
    this.input(false);
  }

  @Emit()
  selectTemplate (template: ExerciseTemplate): ExercisePreviewTemplateEnum {
    return template.type as ExercisePreviewTemplateEnum;
  }

  @Emit()
  input (value: boolean): boolean {
    return value;
  }

  @Emit() setVariantIndex (index: number): number {
    return index;
  }
}
