export const charMap = {
  default: [
    [0x2615, 'morning coffee'],
    ['8216', 'left single quotation mark'],
    ['9723', 'ballot box'],
    ['8217', 'right single quotation mark'],
    ['8220', 'left double quotation mark'],
    ['8221', 'right double quotation mark'],
    ['8218', 'single low-9 quotation mark'],
    ['8222', 'double low-9 quotation mark'],
    ['162', 'cent sign'],
    ['8364', 'euro sign'],
    ['163', 'pound sign'],
    ['165', 'yen sign'],
    ['196', 'A - diaeresis'],
    ['214', 'O - diaeresis'],
    ['220', 'U - diaeresis'],
    ['228', 'a - diaeresis'],
    ['246', 'o - diaeresis'],
    ['252', 'u - diaeresis'],
    ['946', 'beta'],
    ['233', 'café']
  ]
};

export const plugins = {
  default: 'template table autoresize table nedoubleunderline badge nonbreaking visualchars visualblocks lists advlist charmap'
};

export const toolbar = {
  default: [
    'mathlive template table ne-insert-image undo redo | fontsize | bold italic underline strikethrough | subscript superscript | forecolor backcolor | removeformat nonbreaking visualchars visualblocks',
    'nesquare nedoubleunderline badge ',
    'alignleft aligncenter alignright alignjustify | outdent | indent | bullist numlist charmap'
  ]
};

export const EXTERNAL_PLUGIN = {
  NE_INSERT_IMAGE: 'ne-insert-image'
};

export const templates = [
  {
    title: 'Testowa templatka',
    description: 'Opis.',
    content: '<p>Last Modified: <time class="mdate">This will be replaced with the date modified.</time></p>'
  }
];
