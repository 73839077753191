
import { Component, Vue } from 'vue-property-decorator';
import {
  ExerciseAttachment,
  ExerciseConfiguration,
  ExerciseContent,
  ExerciseFooter,
  ExitExerciseModal
} from './components';
import { DeleteExerciseModal, PageContent } from '@/shared/components';
import {
  AnswersType,
  AnswerType,
  Bundle,
  ExerciseCreateData,
  ExerciseAnswer,
  ExerciseAnswerAdditional,
  ExercisesFilters,
  ExerciseToSave,
  PageMode,
  PageModeName,
  SaveError,
  Source,
  Variant
} from '@/models/exercises';
import { NeCheckbox, NeSelect } from '@ne/ne-vue2-lib';
import { namespace } from 'vuex-class';
import {
  DELETE_EXERCISE,
  FILTER_EXERCISES,
  REMOVE_NOTIFICATION,
  SET_CATEGORY_KEY,
  SET_DEFAULTS, SET_PAGE_ID,
  UPDATE_CACHED_EXERCISES, UPDATE_TEST_AFTER_EXERCISE_SAVE, UPDATE_TEST_TIME,
  USER_NOTIFY
} from '@/store/list';
import { EXERCISE_CREATE, EXERCISE_EDIT, EXERCISE_SAVE, EXERCISE_UPDATE } from '@/api/eob/endpoints';
import { RawLocation, Route } from 'vue-router';
import { Active, GtPageTypes, pageDimensions, resourcePath, ROLES } from '@/config';
import { loadCss, unloadCss } from '@/helpers/dom-operations';
import { scrollToElement } from '@/helpers/scroll';
import {
  isPublisher,
  isRedactorExam, isRedactorExamOrNsml,
  isRedactorGroup,
  isRedactorNsml,
  isSuperUser,
  isTeacherGroup
} from '@/helpers/roles';
import { LoadingStatus, LoadingStatusName } from '@/shared/models/loading';
import { categoryInfo } from '@/helpers/category-key-info';
import { CategoryInfoLevel } from '@/models/category-info';
import { LayoutModel, MenuModel } from '@/store/layout-store/types';
import { CLogLevel, ContentType, EventName } from '@/models/gtm';
import { exerciseActivityInfo } from '@/helpers/activity-info';
import { answerTypeText, exerciseLevel, exerciseLevels, isExerciseOldType, sectionName } from '@/helpers/exercise';
import { RouteName } from '@/router/models';
import { answersTypes } from '@/shared/data/exercises';
import { ExerciseDetails, Notification, NotificationConfig } from '@/models';
import { ExercisePreviewTemplateEnum } from './components/ExerciseContent/ExerciseTemplatePreviewModal/exercise-preview-template.enum';
import ExerciseAnswerTypes from './components/ExerciseAnswerTypes/ExerciseAnswerTypes.vue';
import { emptyExercise } from '@/views/Generator/ExerciseComposer/mocks/mocks';
import { deepClone } from '@/helpers/object-manipulation';
import ConfirmationModal from '@/shared/components/ConfirmationModal/ConfirmationModal.vue';
import { createBundleFactory } from '@/mocks/factories/createMockBundle';
import { createAnswerFactory } from '@/mocks/factories/createAnswerMock';
import { ValidationErrorEnum } from '@/views/Generator/ExerciseComposer/models';
import { EditorModeName } from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/models';
import { EXERCISES_URI } from '@/api/generator/endpoints';
import GtMenu from '@/views/Generator/components/GtMenu/GtMenu.vue';

Component.registerHooks(['beforeRouteLeave', 'beforeRouteEnter']);

const AppStore = namespace('AppStore');
const AuthStore = namespace('AuthStore');
const ExerciseStore = namespace('ExerciseStore');
const LayoutStore = namespace('LayoutStore');
const TestStore = namespace('TestStore');

@Component({
  name: 'ExerciseComposer',
  components: {
    GtMenu,
    ConfirmationModal,
    ExerciseAnswerTypes,
    DeleteExerciseModal,
    PageContent,
    ExerciseConfiguration,
    ExerciseContent,
    ExerciseAttachment,
    ExerciseFooter,
    ExitExerciseModal,
    NeSelect,
    NeCheckbox
  }
})
export default class ExerciseComposer extends Vue {
  answersGroupError: Notification[] | null = [];
  AnswerTypes: AnswerType[] = answersTypes;
  AnswersTypes = AnswersType;
  chapterName: string[];
  currentVariantIndex = 0;
  currentBundleIndex = 0;
  emptyAnswer: ExerciseAnswer = { ...createAnswerFactory() };

  RouteName = RouteName;
  formData: FormData = new FormData();
  initialExercise = '';
  initialStatus: Active = Active.DRAFT;
  isAnswerDataLooseModalShow = false;
  isCreateMode = false;
  isExitExerciseModalVisible = false;
  isFetched = false;
  isLoaded = false;
  isPageLeavePossible = false;
  isPreviewModalShown = false;
  isSavingCompleted: boolean = true;
  isValidationActive = false;
  leaveRoute: RawLocation | null = null;
  level: string;
  loadingStatus: LoadingStatusName = LoadingStatus.INCOMPLETE;
  maxContentHeight: number = 0;
  pageHeight: number = 0;
  tmpExerciseType: AnswerType | null = null;
  scripts: string[] = [
    'assets/jquery/jquery.min.js',
    'assets/jquery.fileupload/jquery.ui.widget.js',
    'assets/jquery.fileupload/jquery.iframe-transport.js',
    'assets/jquery.fileupload/jquery.fileupload.js',
    'assets/jquery-ui/jquery-ui.min.js',
    'assets/perfect-scrollbar/dist/perfect-scrollbar.min.js'
  ];

  showDeleteExerciseModal = false;
  stylesheets: string[] = ['assets/jquery-ui/jquery-ui.min.css', 'assets/perfect-scrollbar/css/perfect-scrollbar.css'];

  exercise: ExerciseCreateData = { ...emptyExercise };
  @LayoutStore.State('menu') menu: MenuModel;
  @AppStore.State('categoryKey') categoryKey: string;
  @AppStore.State('userRole') userRole: ROLES[];
  @AuthStore.State('isUserLogged') isUserLogged: boolean;
  @LayoutStore.State('layout') layout: LayoutModel;

  get highestVariantPrintHeight (): number {
    const arr: number[] = [];
    this.exercise.variants.forEach(item => { arr.push(item.printHeight); });
    return Math.max(...arr);
  }

  get isExerciseOldType (): boolean | null {
    return isExerciseOldType(this.exercise.answersType);
  }

  get pageMode (): PageModeName {
    const routeName = this.$route.name;
    return routeName === RouteName.EXERCISE_EDITOR ? PageMode.EDITOR : PageMode.COMPOSER;
  }

  get isSuperUser (): boolean {
    return isSuperUser(this.userRole);
  }

  get isPublisher (): boolean {
    return isPublisher(this.userRole);
  }

  get isRedactor (): boolean {
    return isRedactorGroup(this.userRole);
  }

  get isRedactorNsmlGroup (): boolean {
    return isRedactorNsml(this.userRole);
  }

  get showDeleteButton (): boolean {
    if (this.userRole.includes(ROLES.ADMIN)) return true;
    if (isRedactorGroup(this.userRole)) return this.initialStatus !== Active.PUBLISHED;
    if (isTeacherGroup(this.userRole)) return this.exercise.isOwner ?? false;
    return false;
  }

  get isEditable (): boolean {
    return this.isSuperUser || this.pageMode === PageMode.COMPOSER || this.exercise.author === Source.MY;
  }

  get isExerciseChanged (): boolean {
    return this.initialExercise !== JSON.stringify(this.exercise);
  }

  get isNewFlagDaysValidationActive (): boolean {
    const newFlagDays = this.exercise.newFlagDays;
    const newFlagDaysValue: number | null = newFlagDays ? newFlagDays.value : null;
    return newFlagDaysValue !== null ? newFlagDaysValue > 1000 || newFlagDaysValue < 0 : isSuperUser(this.userRole);
  }

  get currentVariant (): Variant {
    return this.exercise.variants[this.currentVariantIndex];
  }

  get isCurrentVariantContentEmpty (): boolean {
    return !this.currentVariant.content || this.currentVariant.content === '';
  }

  get isCurrentVariantHeightValid (): boolean {
    return !this.isExerciseHeightValidationActive || (this.currentVariant.printHeight < this.getPageHeight());
  }

  get isCurrentVariantSolutionInvalid (): boolean {
    return this.currentVariant.isSolutionInvalid === true;
  }

  get isAnyAnswerEmpty (): boolean {
    if (this.exercise.variants[this.currentVariantIndex].answers?.length) {
      return this.exercise.variants[this.currentVariantIndex].answers!.some(answer => !answer.content);
    }
    return false;
  }

  get isAnyAnswerChecked (): boolean | null {
    if (this.exercise.variants[this.currentVariantIndex].answers?.length) {
      return this.exercise.answersType !== AnswersType.BOOLEAN && this.exercise.answersType !== AnswersType.ASSIGNMENT ? this.exercise.variants[this.currentVariantIndex].answers!.some(answer => answer.isCorrect) : true;
    }
    return false;
  }

  get isEveryBooleanAnswerChecked (): boolean | null {
    if (this.exercise.variants[this.currentVariantIndex].answers?.length) {
      return this.exercise.answersType === AnswersType.BOOLEAN ? !this.exercise.variants[this.currentVariantIndex].answers!.map(answer => answer.booleanAnswer).includes(null) : true;
    }
    return false;
  }

  get hasLessThanTwoAnswers (): boolean {
    if (this.exercise.variants[this.currentVariantIndex].answers) {
      return this.exercise.variants[this.currentVariantIndex].answers!.length < 2;
    }
    return false;
  }

  get bundleHasLessThanTwoAnswers (): boolean {
    return this.currentVariant.bundles![this.currentBundleIndex].answers!.length < 2;
  }

  get isAnyBundleAnswerEmpty (): boolean {
    return this.currentVariant.bundles![this.currentBundleIndex].answers!.some(answer => !answer.content);
  }

  get isAnyBundleAnswerChecked (): boolean {
    return this.answersType === AnswersType.BUNDLES_EXAM ? false : this.currentVariant.bundles![this.currentBundleIndex].answers!.some(answer => answer.isCorrect);
  }

  get bundlesMessageError (): string {
    if (this.bundleHasLessThanTwoAnswers && this.answersType !== AnswersType.BUNDLES_EXAM) return this.getMessage('invalid_answers_length');
    if (this.isAnyBundleAnswerEmpty) return this.getMessage('empty_answer');
    if (!this.isAnyBundleAnswerChecked && this.answersType === AnswersType.BUNDLES) return this.getMessage('answer_not_checked_single_choice');
    return '';
  }

  get answersHasError (): boolean {
    return (this.isExerciseOldType || [AnswersType.BUNDLES, AnswersType.BUNDLES_EXAM].includes(this.answersType as AnswersType)) ? false : (this.hasLessThanTwoAnswers || this.isAnyAnswerEmpty || !this.isAnyAnswerChecked || !this.isEveryBooleanAnswerChecked);
  }

  get hasTheSameAdditionalAnswersInEachVariant (): boolean {
    const firstVariantAnswersLength = this.exercise.variants[0].additionalAnswers?.length ?? 0;
    return this.exercise.variants.every(variant => variant.additionalAnswers?.length === firstVariantAnswersLength);
  }

  get isAtLeastOneCorrectAnswerSelected (): boolean {
    return this.currentVariant.additionalAnswers!.every(answer => answer.correctAnswers.length > 0);
  }

  get isAnyAdditionalAnswerEmpty (): boolean {
    return this.currentVariant.additionalAnswers!.some(answer => !answer.content);
  }

  get isAnyAdditionalAnswerChecked (): boolean {
    return this.currentVariant.additionalAnswers!.some(answer => answer.isCorrect);
  }

  get assignmentAnswersError (): string {
    if (!this.isAtLeastOneCorrectAnswerSelected) return this.getMessage('answer_not_assigned');
    return '';
  }

  get justificationAnswersError (): string {
    if (this.isAnyAdditionalAnswerEmpty) return this.getMessage('empty_answer');
    if (!this.isAnyAdditionalAnswerChecked) return this.getMessage('answer_not_checked');
    return '';
  }

  get justificationWordError (): string {
    if (this.currentVariant.justificationWord === '' || this.currentVariant.justificationWord === null) return this.getMessage('justification_word_empty');
    return '';
  }

  get answersMessageError (): string {
    if (this.hasLessThanTwoAnswers) return this.getMessage('invalid_answers_length');
    if (this.isAnyAnswerEmpty) return this.getMessage('empty_answer');
    if (!this.isAnyAnswerChecked || !this.isEveryBooleanAnswerChecked) return this.exercise.answersType === AnswersType.SINGLE_CHOICE ? this.getMessage('answer_not_checked_single_choice') : this.getMessage('answer_not_checked');
    return '';
  }

  getIndexOfBundleError (variantIndex: number): number {
    const bundleIndex = this.exercise.variants[variantIndex].bundles!.findIndex(b => {
      const hasBundleLessThanTwoAnswers = b.answers!.length < 2;
      const hasBundleAnyEmptyAnswer = b.answers!.some(answer => !answer.content);
      const hasAnyBundleAnswerChecked = b.answers!.some(answer => answer.isCorrect);

      return this.answersType === AnswersType.BUNDLES ? !hasAnyBundleAnswerChecked || hasBundleAnyEmptyAnswer || hasBundleLessThanTwoAnswers : hasBundleAnyEmptyAnswer || hasBundleLessThanTwoAnswers;
    });
    return bundleIndex < 0 ? 0 : bundleIndex;
  }

  get indexOfVariantError (): number {
    return this.exercise.variants.findIndex(v => {
      const hasLessThanTwoAnswers = (v.answers && v.answers?.length < 2);
      const hasAnyEmptyAnswer = v.answers?.some(answer => !answer.content);
      const hasAllAnswersIncorrect = this.exercise.answersType !== AnswersType.BOOLEAN && this.exercise.answersType !== AnswersType.ASSIGNMENT && v.answers?.every(answer => !answer.isCorrect);
      const hasAllBooleanAnswersChecked = this.variantHasAllBooleanAnswersChecked(v);
      const hasAnyEmptyAssignmentAnswer = this.answersType !== AnswersType.ASSIGNMENT ? v.additionalAnswers?.some(answer => !answer.content) : false;
      const hasAnyNotAssignedAnswer = this.answersType === AnswersType.ASSIGNMENT && v.additionalAnswers?.some(answer => answer.correctAnswers.length === 0);

      const hasBundleLessThanTwoAnswers = this.answersType !== AnswersType.BUNDLES_EXAM && v.bundles?.some(b => b.answers!.length < 2);
      const hasBundleAnyEmptyAnswer = v.bundles?.some(b => b.answers!.some(answer => !answer.content));
      const hasAnyBundleAnswerChecked = v.bundles?.some(b => !b.answers!.some(answer => answer.isCorrect));
      const hasAnswersError = (!this.isExerciseOldType && ![AnswersType.BUNDLES, AnswersType.BUNDLES_EXAM].includes(this.answersType as AnswersType)) && (hasLessThanTwoAnswers || hasAnyEmptyAnswer || hasAllAnswersIncorrect || hasAllBooleanAnswersChecked);
      const hasBundleAnswersError = ([AnswersType.BUNDLES, AnswersType.BUNDLES_EXAM].includes(this.answersType as AnswersType) && !this.isExerciseOldType) && (this.answersType === AnswersType.BUNDLES ? (hasBundleLessThanTwoAnswers || hasBundleAnyEmptyAnswer || hasAnyBundleAnswerChecked) : (hasBundleAnyEmptyAnswer || hasBundleLessThanTwoAnswers));
      const hasAnyJustificationWordEmpty = (this.answersType === AnswersType.JUSTIFICATION) && (v.justificationWord === '' || v.justificationWord === null);
      const isVariantMaxHeightValidationActive = this.isExerciseHeightValidationActive && v.printHeight > this.getPageHeight();

      return !v.content ||
        v.content === '' ||
        isVariantMaxHeightValidationActive ||
        v.isSolutionInvalid ||
        hasAnswersError ||
        hasBundleAnswersError ||
        hasAnyEmptyAssignmentAnswer ||
        hasAnyNotAssignedAnswer ||
        hasAnyJustificationWordEmpty;
    });
  }

  get validationErrors (): any[] {
    return this.exercise
      ? [
        {
          name: 'time-in-minutes',
          isError: this.exercise.time.value === null || this.exercise.time.value < 1 || this.exercise.time.value > 45,
          msg: this.getMessage('invalid_time_in_minutes')
        },
        {
          name: 'new-flag-days',
          isError: this.isNewFlagDaysValidationActive,
          msg: this.getMessage('invalid_new_days')
        },
        {
          name: 'section',
          isError: this.exercise.section.value === null,
          msg: this.getMessage('section_not_selected')
        },
        {
          name: 'title',
          isError: this.exercise.title && this.exercise.title.value && this.exercise.title.value.length > 60,
          msg: this.getMessage('title_too_long')
        },
        {
          name: 'content',
          isError: this.isCurrentVariantContentEmpty || !this.isCurrentVariantHeightValid,
          msg: this.isCurrentVariantContentEmpty
            ? this.getMessage('variant_without_content')
            : this.getMessage('variant_with_invalid_content')
        },
        {
          name: 'solution',
          isError: this.isCurrentVariantSolutionInvalid,
          msg: this.getMessage('variant_with_invalid_content')
        },
        {
          name: 'variant',
          isError: this.indexOfVariantError >= 0,
          msg: ''
        },
        {
          name: 'score',
          isError: this.exercise.score.value === null || this.exercise.score.value < 1 || this.exercise.score.value > 10,
          msg: this.getMessage('invalid_score')
        },
        {
          name: 'range',
          isError: !!this.exercise.range && !this.exercise.range.value,
          msg: this.getMessage('invalid_range')
        },
        {
          name: 'comment',
          isError: this.isMaxHtmlLengthExceeded(this.exercise.comment.value),
          msg: this.getMessage('invalid_comment')
        },
        {
          name: 'copyright',
          isError: this.isMaxHtmlLengthExceeded(this.exercise.copyright),
          msg: this.getMessage('invalid_comment')
        },
        {
          name: 'requirements',
          isError: this.isMaxHtmlLengthExceeded(this.exercise.requirements.value),
          msg: this.getMessage('invalid_requirements')
        },
        // TODO: refactor answer not null checking. Can do this right here instead checking each function
        {
          name: 'answers',
          isError: this.answersHasError,
          msg: this.answersMessageError
        },
        {
          name: 'bundles',
          isError: [AnswersType.BUNDLES, AnswersType.BUNDLES_EXAM].includes(this.answersType as AnswersType) && !!this.bundlesMessageError,
          msg: [AnswersType.BUNDLES, AnswersType.BUNDLES_EXAM].includes(this.answersType as AnswersType) ? this.bundlesMessageError : ''
        },
        {
          name: ValidationErrorEnum.ASSIGNMENT_ANSWERS,
          isError: this.answersType === AnswersType.ASSIGNMENT && this.assignmentAnswersError !== '',
          msg: this.answersType === AnswersType.ASSIGNMENT ? this.assignmentAnswersError : ''
        },
        {
          name: ValidationErrorEnum.JUSTIFICATION_ANSWERS,
          isError: this.answersType === AnswersType.JUSTIFICATION && this.justificationAnswersError !== '',
          msg: this.answersType === AnswersType.JUSTIFICATION ? this.justificationAnswersError : ''
        },
        {
          name: ValidationErrorEnum.JUSTIFICATION_WORD,
          isError: this.answersType === AnswersType.JUSTIFICATION && this.justificationWordError !== '',
          msg: this.answersType === AnswersType.JUSTIFICATION ? this.justificationWordError : ''
        }
      ]
      : [];
  }

  get stringifiedErrors (): string {
    return JSON.stringify(this.validationErrors);
  }

  get isValid (): boolean {
    return this.validationErrors.filter(error => error.isError).length === 0;
  }

  get answersType (): AnswersType | null {
    return this.exercise.answersType;
  }

  get exerciseLevel (): string | null {
    return exerciseLevel(exerciseLevels(this.exercise), this.exercise) || (this.level ?? null);
  }

  get allVariantsHasAnswersArr (): boolean {
    return this.exercise.variants!.every(variant => variant.answers?.length);
  }

  get allVariantsHasAdditionalAnswersArr (): boolean {
    return this.exercise.variants!.every(variant => variant.additionalAnswers?.length);
  }

  get allBundlesHasAnswersArr (): boolean {
    return this.exercise.variants!.every(variant => variant.bundles?.length && variant.bundles?.every(b => b.answers?.length));
  }

  get correctAnswersPerVariantMap (): number[] | null {
    if (this.allVariantsHasAnswersArr) {
      return this.exercise.variants!.map(variant => variant.answers!.filter(answer => answer.isCorrect).length);
    }
    return null;
  }

  get hasEqualCorrectAnswersCountPerVariant (): boolean | null {
    if (this.allVariantsHasAnswersArr) {
      return this.correctAnswersPerVariantMap!.every((val, i, arr) => val === arr[0]);
    }
    return null;
  }

  get hasTheSameAnswersInEachVariant (): boolean {
    const firstVariantAnswersLength = this.exercise.variants[0].answers?.length ?? 0;
    return this.exercise.variants.every(variant => variant.answers?.length === firstVariantAnswersLength);
  }

  hasBundleTheSameAnswersLengthPerVariant (variants: Variant[]): boolean {
    const mappedVariantBundleAnswersLengthArr: number[][] = variants
      .map(variant => variant.bundles!
        .map(bundle => bundle.answers!.length));
    const isProperAnswersLengthInsideBundle = mappedVariantBundleAnswersLengthArr.every((variant, i, arr) => {
      const eachBundleHasTheSameAnswersLength = arr[0].every(el => el === arr[0][0]);
      const currentVariantHasEqualBundleAnswersLength = variant.toString() === arr[0].toString();
      return eachBundleHasTheSameAnswersLength && currentVariantHasEqualBundleAnswersLength;
    });
    const answersLength = mappedVariantBundleAnswersLengthArr[0][0];
    const isProperAnswersLengthInsideVariant = mappedVariantBundleAnswersLengthArr.every(variant => {
      return variant.every(bundle => bundle === answersLength);
    });
    return isProperAnswersLengthInsideBundle && isProperAnswersLengthInsideVariant;
  }

  get isExerciseChangeDisabled (): boolean {
    const isNeExerciseChangeDisabled = this.exercise.author === Source.NOWA_ERA && isTeacherGroup(this.userRole);
    const isNewTypeExerciseChangeDisabled = (isSuperUser(this.userRole) || isPublisher(this.userRole)) && !this.isExerciseOldType! && this.exercise.active === Active.PUBLISHED;
    return isNeExerciseChangeDisabled || isNewTypeExerciseChangeDisabled;
  }

  get exerciseTypeName (): string | null {
    return answerTypeText(this.exercise.answersType) ?? null;
  }

  variantHasAllBooleanAnswersChecked (variant: Variant): boolean {
    if (this.exercise.answersType === AnswersType.BOOLEAN) {
      return variant.answers!.some(answer => answer.booleanAnswer === null);
    }
    return false;
  }

  sectionName (): string | null {
    return sectionName((this.exercise.section.options as any[]), this.exercise.section.value ?? 0);
  }

  async created (): Promise<void> {
    this.setPageId(GtPageTypes.EXERCISES_DATABASE);
    const categoryKey = this.$route.params.categoryKey.replace(/,/g, '/');
    this.isCreateMode = this.pageMode === PageMode.COMPOSER;
    if (categoryKey !== this.categoryKey) {
      this.setCategoryKey(categoryKey);
      await this.setDefaults();
    }
    await this.initializeExercise();

    for (const script of this.scripts) {
      await this.$loadScript(`${resourcePath}${script}`);
    }

    for (const stylesheet of this.stylesheets) {
      loadCss(`${resourcePath}${stylesheet}`);
    }
    this.isLoaded = true;
  }

  mounted (): void {
    window.onbeforeunload = this.handlePageUnload;
  }

  async destroyed (): Promise<void> {
    window.onbeforeunload = null;

    for (const script of this.scripts) {
      await this.$unloadScript(`${resourcePath}${script}`);
    }
    for (const stylesheet of this.stylesheets) {
      unloadCss(`${resourcePath}${stylesheet}`);
    }
    this.removeGroupErrors();
  }

  beforeRouteEnter (to: Route, from: Route, next: any): void {
    next((vm: any) => {
      if (from.name && !!sessionStorage.getItem('testInEdition')) vm.leaveRoute = from;
    });
  }

  beforeRouteLeave (to: RawLocation, from: RawLocation, next: any): void {
    if (!this.isExitExerciseModalVisible && this.isExerciseChanged) {
      this.showExitExerciseModal(to);
    } else {
      next();
    }
  }

  removeGroupErrors (): void {
    this.answersGroupError?.forEach(item => this.removeNotification(item));
  }

  @AppStore.Mutation(SET_PAGE_ID) setPageId: (id: GtPageTypes) => void;
  @AppStore.Mutation(SET_CATEGORY_KEY) setCategoryKey?: any;
  @AppStore.Action(SET_DEFAULTS) setDefaults: any;
  @AppStore.Action(USER_NOTIFY) notify: (data: NotificationConfig) => Promise<Notification>
  @ExerciseStore.Action(DELETE_EXERCISE) deleteExercise: any;
  @ExerciseStore.Action(FILTER_EXERCISES) filterExercises: (data: ExercisesFilters) => Promise<void>;
  @AppStore.Mutation(REMOVE_NOTIFICATION) removeNotification: (n: Notification | null) => void;
  @ExerciseStore.Action(UPDATE_CACHED_EXERCISES) updateCachedExercises: (exercise: ExerciseDetails) => Promise<void>;
  @TestStore.Action(UPDATE_TEST_TIME) updateTestTime!: () => void;
  @TestStore.Mutation(UPDATE_TEST_AFTER_EXERCISE_SAVE) updateTestAfterExerciseSave!: (exercise: ExerciseDetails) => void;

  async initExerciseEdit (): Promise<void> {
    const exerciseId = +this.$route.params.exerciseId;
    try {
      this.exercise = (await this.$api.get<ExerciseCreateData>(EXERCISE_EDIT(exerciseId.toString()))).data;
      this.maxContentHeight = this.getPageHeight();
      this.exercise.time.required = true;
      this.handleEditingNeExerciseForTeacher();
      this.isFetched = true;
    } catch (e) {
      this.loadingStatus = LoadingStatus.ERROR;
    }
  }

  handleEditingNeExerciseForTeacher (): void {
    if (isTeacherGroup(this.userRole) && !this.isExerciseOldType) {
      if (this.answersType !== AnswersType.BUNDLES && !this.hasTheSameAnswersInEachVariant) {
        this.addAnswersForTeacher();
      } else if (this.answersType === AnswersType.BUNDLES && !this.hasBundleTheSameAnswersLengthPerVariant(this.exercise.variants)) {
        this.addBundleAnswersForTeacher();
      } else if ((this.answersType === AnswersType.ASSIGNMENT || this.answersType === AnswersType.JUSTIFICATION) && !this.hasTheSameAdditionalAnswersInEachVariant) {
        this.addAdditionalAnswersForTeacher();
      }
    }
  }

  async initExerciseCompose (): Promise<void> {
    this.exercise.time.required = true;
    this.maxContentHeight = this.getPageHeight();
    const author = isSuperUser(this.userRole) ? Source.NOWA_ERA : Source.MY;
    this.exercise.author = author;
    this.exercise.variants[0].author = author;
    this.exercise.variants[0].hints = [];
    this.exercise.active = Active.DRAFT;
    this.exercise.answersType = null;
    this.exercise.score.value = 1;
    this.exercise.additionalContent = { source: '', content: '' };
    this.isFetched = true;
  }

  async initializeExercise (): Promise<void> {
    const routeName = this.$route.name;
    if (routeName === RouteName.EXERCISE_EDITOR) {
      await this.initExerciseEdit();
    } else {
      try {
        this.exercise = (await this.$api.get<ExerciseCreateData>(EXERCISE_CREATE(this.categoryKey))).data;
        await this.initExerciseCompose();
      } catch (e) {
        this.loadingStatus = LoadingStatus.ERROR;
      }
    }
    if (this.isFetched) {
      this.pageHeight = this.getPageHeight();
      this.initialStatus = this.exercise.active || Active.DRAFT;
      this.initialExercise = JSON.stringify(this.exercise);
      this.loadingStatus = LoadingStatus.COMPLETE;
    }
    this.pageHeight = this.getPageHeight();
    this.initialStatus = this.exercise.active || Active.DRAFT;
    this.initialExercise = JSON.stringify(this.exercise);
  }

  addAnswersForTeacher () {
    const neVariantAnswers = this.exercise.variants[0].answers;
    this.exercise.variants.forEach(teacherVariant => {
      if (teacherVariant.answers!.length < neVariantAnswers!.length) {
        this.equalizeAnswersForTeacher(teacherVariant.answers, neVariantAnswers!.length);
      }
    });
  }

  addAdditionalAnswersForTeacher () {
    const neAdditionalAnswersLength = this.exercise.variants[0].additionalAnswers!.length;
    const neAnswersLength = this.exercise.variants[0].answers!.length;
    this.exercise.variants.forEach((variant: Variant | null) => {
      if (variant!.additionalAnswers!.length < neAdditionalAnswersLength) {
        const answersLengthDiff = neAdditionalAnswersLength - variant!.additionalAnswers!.length;
        [...Array(answersLengthDiff)].forEach(() => {
          variant!.additionalAnswers!!.push({ ...createAnswerFactory({ correctAnswers: [] }) });
        });
      }
      if (variant!.answers!.length < neAnswersLength) {
        this.equalizeAnswersForTeacher(variant!.answers!, neAnswersLength);
      }
    });
  }

  equalizeAnswersForTeacher (teacherAnswers: ExerciseAnswer[] | null, correctAnswersLength: number): void {
    const answersLengthDiff = correctAnswersLength - teacherAnswers!.length;
    [...Array(answersLengthDiff)].forEach(() => {
      teacherAnswers!.push({ ...this.emptyAnswer });
    });
  }

  addBundleAnswersForTeacher () {
    const bundlesLength = this.exercise.variants[0].bundles!.length;
    const answersLength = this.exercise.variants[0].bundles![0].answers!.length;
    const emptyAnswersArr = Array(answersLength).fill({ ...createAnswerFactory() });
    const emptyBundle: Bundle = createBundleFactory({ answers: [...emptyAnswersArr] });
    const emptyBundleArr = Array(bundlesLength).fill(Object.assign(emptyBundle, {}));
    this.exercise.variants.forEach(variant => {
      if (variant.bundles === null || variant.bundles.length < bundlesLength) {
        variant.bundles = [...deepClone(emptyBundleArr)];
      }
      variant.bundles.forEach(bundle => {
        if (bundle.answers!.length < answersLength) {
          this.equalizeAnswersForTeacher(bundle.answers, answersLength);
        }
      });
    });
  }

  chapterAndSelectionChange (chapterAndSectionLabel: string): void {
    const chapterAndSectionLabelArr = chapterAndSectionLabel.split(' ➡ ');
    this.chapterName = [chapterAndSectionLabelArr[0]];
  }

  changeLevel (level: string): void {
    this.level = level;
  }

  getMessage (key: string): string {
    return this.$tc(`EXERCISE_COMPOSER.validation_messages.${key}`);
  }

  handlePageUnload (e: any): null | void {
    if (this.isExerciseChanged) {
      e.preventDefault();
      (e || window.event).returnValue = null;
      return null;
    }
  }

  exitPage (): void {
    if (this.leaveRoute) this.$router.push(this.leaveRoute);
  }

  showExitExerciseModal (to: RawLocation): void {
    this.leaveRoute = to;
    this.isExitExerciseModalVisible = true;
  }

  leaveExercise (): void {
    document.body.scrollIntoView();
    const leaveRoute = this.leaveRoute || { name: 'exercises' };
    this.$router.push(leaveRoute);
  }

  isMaxHtmlLengthExceeded (text: string | null): boolean {
    const regExp = new RegExp(/(?:<\/?br\s?\/?>|<p[^>]*><\/p[^>]*>|<p[^>]*>[A-Za-z0-9_])/gim);
    const maxCharacters = 1000;
    const maxLines = 20;
    if (text && text.length > maxCharacters) return true;
    const matchedText = text && text.match(regExp);
    if (matchedText && matchedText.length > maxLines) return true;
    return false;
  }

  gtmExerciseSave () {
    setTimeout(() => {
      this.$piwik.push({
        bookyear: categoryInfo(this.categoryKey, CategoryInfoLevel.BOOKYEAR),
        c_log_level: CLogLevel.CONTENT,
        chapter: this.exercise.chapter ? [this.exercise.chapter] : this.chapterName ?? null,
        content_author: this.exercise.author ?? null,
        content_difficulty: this.exerciseLevel,
        content_id: this.exercise.id?.toString() ? this.exercise.id.toString() : null,
        content_name: this.exercise.title?.value ? this.exercise.title?.value.toString() : null,
        content_set: null,
        content_status: exerciseActivityInfo(this.exercise.active),
        content_subtype: null,
        content_type: ContentType.EXERCISE,
        content_version: this.exercise.variants[this.currentVariantIndex].id ? this.exercise.variants[this.currentVariantIndex].id?.toString() : null,
        education_level: categoryInfo(this.categoryKey, CategoryInfoLevel.EDUCATION_LEVEL),
        event: EventName.CREATE,
        method: categoryInfo(this.categoryKey, CategoryInfoLevel.METHOD),
        page_path: this.$route.path,
        section: this.sectionName() ? [this.sectionName()] : null,
        subject: categoryInfo(this.categoryKey, CategoryInfoLevel.SUBJECT)
      });
    });
  }

  editGtmPush (): void {
    this.$piwik.push({
      bookyear: categoryInfo(this.categoryKey, CategoryInfoLevel.BOOKYEAR),
      c_log_level: CLogLevel.CONTENT,
      chapter: this.exercise.chapter,
      section: this.exercise.section,
      content_author: this.exercise.author,
      content_difficulty: this.exercise.level,
      content_id: null,
      content_name: this.exercise.title,
      content_set: null,
      content_status: exerciseActivityInfo(this.exercise.active),
      content_subtype: null,
      content_type: ContentType.EXERCISE,
      content_version: null,
      is_teacher_role: isTeacherGroup(this.userRole),
      education_level: categoryInfo(this.categoryKey, CategoryInfoLevel.EDUCATION_LEVEL),
      event: EventName.EDIT,
      method: categoryInfo(this.categoryKey, CategoryInfoLevel.METHOD),
      page_path: this.$route.path,
      subject: categoryInfo(this.categoryKey, CategoryInfoLevel.SUBJECT)
    });
  }

  handlePiwikEvent (): void {
    if (this.pageMode === PageMode.COMPOSER) {
      this.gtmExerciseSave();
    } else {
      this.editGtmPush();
    }
  }

  handleInvalidExerciseOnSave (): void {
    if (this.indexOfVariantError >= 0) {
      this.currentVariantIndex = this.indexOfVariantError;
      this.currentBundleIndex = [AnswersType.BUNDLES, AnswersType.BUNDLES_EXAM].includes(this.answersType as AnswersType) ? this.getIndexOfBundleError(this.currentVariantIndex) : this.currentBundleIndex;
    }

    this.$nextTick(() => {
      const errorDiv = document.getElementsByName('gt-validation-error')[0];
      if (errorDiv) scrollToElement(errorDiv, 20);
    });
  }

  async handleErrorResponse (responseData: any): Promise<void> {
    const errorResponse: SaveError = responseData;
    const errors = [];
    for (const key in errorResponse.error) {
      const error = {
        name: key,
        description: errorResponse.error[key]
      };
      errors.push(error);
    }
    const errorMessage = `<ul>${errors.map(e => `<li>${e.name}: <strong>${e.description}</strong></li>`)}</ul>`;
    await this.notify({ msg: errorMessage, type: 'error' });
    this.isSavingCompleted = true;
  }

  async handleSuccessExerciseSave (responseData: ExerciseCreateData): Promise<boolean> {
    this.exercise = responseData;
    this.isCreateMode = false;
    this.initialExercise = JSON.stringify(this.exercise);
    if (this.exercise.active) this.initialStatus = this.exercise.active;
    await this.notify({
      msg: `<div>
            <p>${this.$tc('NOTIFICATIONS.exercise_saved')}</p>
      </div>`,
      type: 'success',
      timeInMs: 3000
    });
    this.isSavingCompleted = true;
    if (this.shouldShowOnlyOneAnswerProvidedInfo()) this.notify({ msg: this.$tc('EXERCISE_COMPOSER.validation_messages.only_one_answer_provided'), type: 'info', timeInMs: 10_000 });
    const exerciseDetailsResponse: ExerciseDetails[] = (await this.$apiGt.get<any[]>(EXERCISES_URI + '/lists/' + responseData.id)).data;
    if (this.exercise.id) {
      await this.updateCachedExercises(exerciseDetailsResponse[0]);
      this.updateTestAfterExerciseSave(exerciseDetailsResponse[0]);
      this.updateTestTime();
    }
    return true;
  }

  prepareFormDataForExerciseSave () {
    this.isSavingCompleted = false;
    const {
      active,
      additionalContent,
      answersTemplate,
      englishTemplate,
      answersType,
      attachments,
      categoryId,
      comment,
      copyright,
      id,
      isAnswerSpace,
      level,
      newAttachment,
      newFlagDays,
      range,
      requirements,
      score,
      section,
      skills,
      source,
      time,
      title,
      variants
    } = this.exercise;

    const exerciseToSave: ExerciseToSave = {
      active,
      additionalContent,
      answersTemplate,
      answersType,
      comment: comment.value,
      copyright,
      englishTemplate,
      id,
      isAnswerSpace,
      level: level ? level.value : null,
      newFlagDays: newFlagDays ? newFlagDays.value : null,
      range: range ? range.value : null,
      requirements: requirements.value,
      score: score.value,
      section: section.value,
      skills: skills ? skills.value : null,
      time: time.value,
      title: title ? title.value : null,
      variants: variants.map(({ answerKey, additionalAnswers, justificationWord, answers, bundles, hints, id, explanation, content, author, solution, printHeight, printHeightSolution }) => {
        return {
          answerKey,
          answers,
          additionalAnswers,
          author,
          bundles,
          content,
          explanation,
          hints,
          id,
          justificationWord,
          printHeight,
          printHeightSolution,
          solution
        };
      }),
      categoryId,
      source: source.value,
      deleteFile: !newAttachment && (!attachments || attachments.length === 0)
    };
    this.formData = new FormData();
    this.formData.append('exercise', JSON.stringify(exerciseToSave));
    this.formData.append('file', newAttachment || '');
  }

  get isExerciseHeightValidationActive (): boolean {
    return !(isRedactorExamOrNsml(this.userRole));
  }

  handleNotifyErrorValidation (): string {
    if (!this.isExerciseOldType && ![AnswersType.BUNDLES, AnswersType.BUNDLES_EXAM].includes(this.answersType as AnswersType) && !this.hasTheSameAnswersInEachVariant) {
      return this.$tc('EXERCISE_COMPOSER.validation_messages.answers_length_is_not_the_same');
    } else if (!this.isExerciseOldType && this.highestVariantPrintHeight > this.getPageHeight() && this.isExerciseHeightValidationActive) {
      return this.$tc('EXERCISE_COMPOSER.validation_messages.invalid_height');
    } else if (this.answersType === AnswersType.MULTIPLE_CHOICE && !this.hasEqualCorrectAnswersCountPerVariant) {
      return this.$tc('EXERCISE_COMPOSER.validation_messages.various_correct_answers');
    } else if ([AnswersType.BUNDLES, AnswersType.BUNDLES_EXAM].includes(this.answersType as AnswersType) && !this.hasBundleTheSameAnswersLengthPerVariant(this.exercise.variants)) {
      return this.$tc('EXERCISE_COMPOSER.validation_messages.bundle_or_answers_length_is_not_the_same');
    } else if ((this.answersType === AnswersType.JUSTIFICATION || this.answersType === AnswersType.ASSIGNMENT) && !this.hasTheSameAdditionalAnswersInEachVariant) {
      return this.$tc('EXERCISE_COMPOSER.validation_messages.answers_length_is_not_the_same');
    }
    return '';
  }

  async saveExercise (): Promise<boolean> {
    const notificationErr = this.handleNotifyErrorValidation();
    this.isValidationActive = true;
    if (!this.isValid) {
      this.handleInvalidExerciseOnSave();
    } else if (!this.isExerciseChanged) {
      await this.notify({
        msg: `<div>
            <p>${this.$tc('NOTIFICATIONS.exercise_saved')}</p>
      </div>`,
        type: 'success',
        timeInMs: 3000
      });
      return true;
    } else if (notificationErr !== '') {
      this.answersGroupError?.push(await this.notify({ type: 'error', permanent: true, msg: notificationErr }));
    } else {
      this.prepareFormDataForExerciseSave();
      try {
        const responseData = (this.exercise.id)
          ? (await this.$api.post<any>(EXERCISE_UPDATE(this.exercise.id), this.formData)).data
          : (await this.$api.post<any>(EXERCISE_SAVE, this.formData)).data;
        if (responseData.error) {
          this.handleErrorResponse(responseData);
        } else {
          this.handleSuccessExerciseSave(responseData);
          return true;
        }
      } catch (e) {
        await this.notify({ msg: this.$tc('EXERCISE_COMPOSER.save_failed'), type: 'error' });
        this.isSavingCompleted = true;
      }
    }
    return false;
  }

  shouldShowOnlyOneAnswerProvidedInfo (): boolean | null {
    return this.correctAnswersPerVariantMap && this.exercise.answersType === AnswersType.MULTIPLE_CHOICE && this.correctAnswersPerVariantMap[0] === 1;
  }

  cancel (): void {
    this.leaveExercise();
  }

  save (): void {
    this.saveExercise();
  }

  async saveAndClose (): Promise<void> {
    const isSaved = await this.saveExercise();
    if (isSaved && this.isValid) {
      this.handlePiwikEvent();
      this.leaveExercise();
    }
  }

  showDeleteModal (): void {
    this.showDeleteExerciseModal = true;
  }

  exerciseDeleted (): void {
    this.showDeleteExerciseModal = false;
    this.leaveExercise();
  }

  getPageHeight (): number {
    return (this.exercise.earlySchoolEducation
      ? pageDimensions.pageContentHeight - pageDimensions.earlyEducationHeaderHeight
      : pageDimensions.pageContentHeight - pageDimensions.pageHeaderHeight);
  }

  getEmptAdditionalAnswers (): ExerciseAnswerAdditional[] {
    return [createAnswerFactory({ correctAnswers: [] }), createAnswerFactory({ correctAnswers: [] })];
  }

  getEmptyAnswers (): ExerciseAnswer[] {
    return [{ ...this.emptyAnswer }, { ...this.emptyAnswer }];
  }

  getDefaultJustificationWord (editor: EditorModeName | null): string {
    if (editor?.includes('angielski')) {
      return 'because';
    } else if (editor?.includes('niemiecki')) {
      return 'weil';
    }
    return 'ponieważ';
  }

  getEmptyVariantForParticularType (answersType: AnswersType, variant: Variant): Variant {
    return {
      bundles: null,
      ...variant,
      answers: this.getEmptyAnswers(),
      additionalAnswers: answersType === AnswersType.ASSIGNMENT || answersType === AnswersType.JUSTIFICATION ? this.getEmptAdditionalAnswers() : null,
      justificationWord: answersType === AnswersType.JUSTIFICATION ? this.getDefaultJustificationWord(this.exercise.editor) : null
    };
  }

  initAnswers (): void {
    this.exercise.variants = this.exercise.variants.map(variant => {
      return this.getEmptyVariantForParticularType(this.answersType as AnswersType, variant);
    });
  }

  removeExerciseAnswers (): void {
    this.exercise.variants.forEach(variant => { variant.answers = []; });
  }

  resetAnswers (): void {
    this.exercise.variants.forEach(variant => {
      variant.bundles = null;
      variant.additionalAnswers = null;
      variant.additionalAnswers = null;
      variant.answers?.forEach(answer => {
        answer.isCorrect = false;
        answer.booleanAnswer = null;
      });
    });
  }

  isDataLooseModalShown (isOldType: boolean | null, currentType: AnswersType | null, newType: AnswersType) {
    const commonTypeArr = [
      AnswersType.MULTIPLE_CHOICE,
      AnswersType.SINGLE_CHOICE,
      AnswersType.BOOLEAN
    ];

    const isChangeFromNewTypeToBundle = !isOldType && newType === AnswersType.BUNDLES && currentType !== null;
    const isChangeFromBundleToNewType = currentType === AnswersType.BUNDLES && !isOldType;
    const isChangeFromNewTypeToJustification = !isOldType && newType === AnswersType.JUSTIFICATION && currentType !== null;
    const isChangeFromJustificationOrAssignmentToNewType = (currentType === AnswersType.JUSTIFICATION || currentType === AnswersType.ASSIGNMENT) && !isOldType;
    const isChangeFormNewTypeToOld = !isOldType && currentType !== null && !commonTypeArr.includes(newType);

    return isChangeFormNewTypeToOld || isChangeFromNewTypeToBundle || isChangeFromBundleToNewType || isChangeFromNewTypeToJustification || isChangeFromJustificationOrAssignmentToNewType;
  }

  onAnswerTypeChange (answerType: AnswerType | null): void {
    this.tmpExerciseType = answerType;
    if (this.isDataLooseModalShown(this.isExerciseOldType, this.answersType, answerType!.id)) {
      this.isAnswerDataLooseModalShow = true;
    } else {
      this.setAnswersType(answerType);
    }
  }

  addEmptyBundleToEachVariant (): void {
    this.exercise.variants.forEach(variant => {
      variant.answers = [];
      if (this.answersType === AnswersType.BUNDLES_EXAM) {
        variant.bundles = [createBundleFactory({ answers: [createAnswerFactory()] })];
      } else {
        variant.bundles = [createBundleFactory()];
      }
    });
  }

  setAnswersType (answerType: AnswerType | null): void {
    this.isValidationActive = false;
    if (answerType!.id) {
      this.exercise = {
        ...this.exercise,
        answersType: answerType!.id
      };
    }
    this.resetAnswers();
    this.initAnswers();
    if ([AnswersType.BUNDLES, AnswersType.BUNDLES_EXAM].includes(this.answersType as AnswersType)) {
      this.currentBundleIndex = 0;
      this.addEmptyBundleToEachVariant();
    }
    this.exercise.answersTemplate = this.getAnswersTemplate();
    if (this.isExerciseOldType) {
      this.removeExerciseAnswers();
    }
    this.isAnswerDataLooseModalShow = false;
  }

  getAnswersTemplate (): ExercisePreviewTemplateEnum | null {
    if (this.isExerciseOldType) return null;
    return this.answersType === AnswersType.BOOLEAN ? ExercisePreviewTemplateEnum.BOOLEAN_EXERCISE : ExercisePreviewTemplateEnum.ONE_COLUMN;
  }

  templatePreviewOpen (val: boolean): void {
    this.isPreviewModalShown = val;
  }
}
