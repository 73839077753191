export default class ImageProcessor {
  private img: HTMLImageElement;
  private file: File;
  private canvas: HTMLCanvasElement;
  private canvasContext: CanvasRenderingContext2D;
  private type: string;
  private maxImageWidth: number = 662;

  public async initProcessor (imgUrl: string): Promise<string> { // Cannot use constructor to get a promise
    return this.initImage(imgUrl, true);
  }

  public readonly getPath: (quality?: number) => string = (quality = 1) => {
    return this.canvas.toDataURL(this.type, quality);
  }

  public readonly toggleBnW: (bnW: boolean) => Promise<string> = (bnW: boolean) => {
    if (bnW) {
      const imgPixels = this.canvasContext.getImageData(0, 0, this.img.width, this.img.height);
      this.makeBnW(imgPixels);
      return new Promise(resolve => resolve(this.getPath()));
    } else {
      return this.initImage(URL.createObjectURL(this.file));
    }
  }

  public readonly dataURLtoFile: (dataurl: string, filename: string) => File = (dataurl: string, filename: string) => {
    const arr = dataurl.split(',');
    // @ts-ignore
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  private initImage: (imgUrl: string, initInstance?: boolean) => Promise<string> = (imgUrl: string, initInstance?: boolean) => {
    return new Promise((resolve) => {
      this.img = new Image();
      this.img.src = imgUrl;

      this.img.onload = async () => {
        if (initInstance) {
          this.type = (await this.getImageBlob(this.img.src)).type;
        }

        this.canvas = document.createElement('canvas');
        this.canvas.width = this.img.width;
        this.canvas.height = this.img.height;
        this.canvasContext = this.canvas.getContext('2d') as CanvasRenderingContext2D;
        this.drawImage(initInstance);

        if (initInstance) {
          this.file = this.dataURLtoFile(this.getPath(), '');
        }
        resolve(this.getPath());
      };
    });
  }

  private readonly makeBnW: (imgPixels: ImageData) => void = (imgPixels: ImageData) => {
    for (let y = 0; y < imgPixels.height; y++) {
      for (let x = 0; x < imgPixels.width; x++) {
        const i = (y * 4) * imgPixels.width + x * 4;
        const avg = (imgPixels.data[i] + imgPixels.data[i + 1] + imgPixels.data[i + 2]) / 3;
        imgPixels.data[i] = avg;
        imgPixels.data[i + 1] = avg;
        imgPixels.data[i + 2] = avg;
      }
    }
    this.canvasContext.putImageData(imgPixels, 0, 0, 0, 0, imgPixels.width, imgPixels.height);
  }

  private readonly drawImage: (compress?: boolean) => void = (compress?: boolean) => {
    if (compress && this.img.width > this.maxImageWidth) {
      const ratio = this.img.width / this.maxImageWidth;
      this.canvas.width = this.maxImageWidth;
      this.canvas.height = this.canvas.height / ratio;
    }
    this.canvasContext.drawImage(this.img, 0, 0, this.canvas.width, this.canvas.height);
  }

  private async getImageBlob (imageUrl: string): Promise<Blob> {
    const response = await fetch(imageUrl);
    return response.blob();
  }
}
