import { render, staticRenderFns } from "./ExerciseAccordion.vue?vue&type=template&id=02673762&"
import script from "./ExerciseAccordion.vue?vue&type=script&lang=ts&"
export * from "./ExerciseAccordion.vue?vue&type=script&lang=ts&"
import style0 from "./ExerciseAccordion.vue?vue&type=style&index=0&id=02673762&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports