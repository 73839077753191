
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AnswersType, ExerciseCreateData, ExerciseTemplate, Variant } from '@/models/exercises';
import { ExercisePreviewTemplateEnum } from '../exercise-preview-template.enum';

@Component({
  name: 'ExercisePreviewContent'
})
export default class ExercisePreviewContent extends Vue {
  @Prop() answerType: AnswersType;
  @Prop() currentBundleIndex: number;
  @Prop() englishTemplate: number;
  @Prop() exercise: ExerciseCreateData;
  @Prop() isTemplatePickerVisible: boolean;
  @Prop() template: ExerciseTemplate | null;
  @Prop() variant: Variant;
  @Prop() variantIndex: number;

  exercisePreviewTemplateEnum = ExercisePreviewTemplateEnum;

  get getAnswers (): string {
    return this.answerType !== AnswersType.BUNDLES ? JSON.stringify(this.variant.answers) : '';
  }

  get getExercise (): string {
    return JSON.stringify(this.exercise);
  }
}
