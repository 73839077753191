
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { NeButton } from '@ne/ne-vue2-lib';

@Component({
  name: 'ExerciseBundlePicker',
  components: {
    NeButton
  }
})

export default class ExerciseBundlePicker extends Vue {
  @Prop() currentIndex: number;
  @Prop() bundlesLength: number;
  @Prop() isAddBtnVisible: boolean;

  get isAddEnabled (): boolean {
    return this.bundlesLength < 10;
  }

  @Emit() onBundleChange (index: number): number { return index; }
  @Emit() onBundleAdd () { }
}
