
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'ExerciseConfigurationSection'
})
export default class ExerciseConfigurationSection extends Vue {
  @Prop() label!: string;
  @Prop({ default: false, type: Boolean }) required!: boolean;
  @Prop() validator: any;
  @Prop() validationActive!: boolean;
  @Prop() labelCentered: boolean;
  @Prop() labelSize: number;

  get isInvalid (): boolean {
    return this.validator ? this.validator.isError && this.validationActive : false;
  }

  get labelStyle () {
    return { fontSize: this.labelSize ? `${this.labelSize}px` : '' };
  }
}
