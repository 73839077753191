
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  AnswersType,
  BooleanAnswerType,
  ExerciseAnswer,
  ExerciseAnswerAdditional,
  ExerciseHint,
  MultiSelectItem,
  Variant
} from '@/models/exercises';
import { NeCheckbox, NeIconButton, NeMultiselect, NeRadioButton, NeSelect, NeTextField } from '@ne/ne-vue2-lib';
import { resourcePath } from '@/config';
import TinyMceEditor from '@tinymce/tinymce-vue';
import { EditorBasicButtonOptions } from '@/shared/components/TextEditor/models';
import { ExerciseAnswerMovePayload } from '@/views/Generator/ExerciseComposer/models/ExerciseAnswerMovePayload';
import { ExerciseImageInsertModal } from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/ExerciseImageInsertModal';
import {
  insertImage,
  specialCharacters
} from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/helpers/editor-utils';
import BooleanExerciseAnswers from './BooleanExerciseAnswers/BooleanExerciseAnswers.vue';
import { GroupMap } from '@/helpers';
import { EditorModeName } from '../../ExerciseContentEditor/models';
import { Tooltip } from '@/shared/components';
import TinyEditor from '@/shared/components/TinyEditor/TinyEditor.vue';
import { SkinName } from '@/shared/components/TinyEditor';
import * as MathLive from 'mathlive/dist/mathlive.js';

@Component({
  name: 'ExerciseAnswersItem',
  components: {
    TinyEditor,
    BooleanExerciseAnswers,
    NeCheckbox,
    NeIconButton,
    NeRadioButton,
    NeSelect,
    NeTextField,
    NeMultiselect,
    TinyMceEditor,
    ExerciseImageInsertModal,
    Tooltip,
    MathLive
  }
})
export default class ExerciseAnswersItem extends Vue {
  $refs: {
    editor: any;
  };

  @Prop() answer: ExerciseAnswer | ExerciseAnswerAdditional;
  @Prop() categoryImageId: number;
  @Prop() correctAnswerIndex: number;
  @Prop() currentVariant: Variant;
  @Prop() editorMode: EditorModeName;
  @Prop() englishTemplate: string[];
  @Prop() hint: ExerciseHint;
  @Prop() index: number;
  @Prop() isCurrentVariantDisabled: boolean;
  @Prop() isEarlySchoolEducation: boolean | null;
  @Prop() isEnglish: boolean;
  @Prop() isExamBundle: boolean;
  @Prop() isExerciseChangeDisabled: boolean | null;
  @Prop() isLastIndex: boolean;
  @Prop({ default: true }) isRemoveBtnEnabled: boolean;
  @Prop() isValidationActive: boolean;
  @Prop() isJustification: boolean;
  @Prop({ default: false }) isNumericAnswer?: boolean;
  @Prop() isAssignment: boolean;
  @Prop({ default: null }) multiselectItems: MultiSelectItem[] | null;
  @Prop({ default: 'answers' }) radioGroupName: string;
  @Prop() selectedItems: string[];
  @Prop() shouldFocus: boolean;
  @Prop() toolbarButtons: string;
  @Prop() type: AnswersType;
  @Prop({ default: true }) isRadioButtonsVisible: boolean;
  @Prop({ default: false }) isExplanation: boolean;
  @Prop({ default: SkinName.TINY_MCE }) skinName: SkinName;

  Type = AnswersType;
  booleanAnswerType = BooleanAnswerType;
  editorInstance: any;
  isInsertImageModalOpen = false;
  isLoaded = false;
  objectId: number = Math.floor(Math.random() * 100000000);
  pluginsPath = `${resourcePath}assets/tinymce/plugins/`;

  @Watch('multiselectItems', { deep: true })
  handler () {
    if (this.isAssignment && this.multiselectItems) {
      (this.answer as ExerciseAnswerAdditional).correctAnswers = this.getCorrectAnswers(this.multiselectItems);
    }
  }

  // this watcher handler prevents problems with editor task queue
  @Watch('answer', { deep: true })
  answerWatchHandler () {
    setTimeout(() => {
      if (this.isLoaded && this.editorInstance?.editor?.getBody()) {
        this.answer.height = this.editorInstance.editor.getBody().clientHeight ?? 0;
        this.editorInstance.editor.execCommand('mceAutoResize');
      }
    });
  }

  @Watch('isCurrentVariantDisabled')
  onDisabledChange (isDisabled: boolean) {
    if (this.editorInstance.editor) this.editorInstance.editor.mode.set(isDisabled ? 'readonly' : 'design');
  }

  get radioButtonsVisible () {
    return this.isExamBundle ? false : this.isRadioButtonsVisible;
  }

  get editorId (): string {
    // TODO: check e2e afetr those changes
    if (this.isAssignment) {
      return `assignment_answer_${this.index}`;
    } else if (this.isJustification) {
      return `justification_answer_${this.index}`;
    } else if (this.isExplanation) {
      return `explanation_${this.index}`;
    }
    return this.isRadioButtonsVisible ? `answer_${this.index}` : `hint_${this.index}`;
  }

  get bodyClasses (): string {
    return `przedmiot_${this.editorMode}${this.isEarlySchoolEducation ? ' early-school-education' : ''}`;
  }

  get editorInit () {
    return {
      autoresize_bottom_margin: 10,
      body_class: this.bodyClasses,
      cache_suffix: `?v=${this.objectId}`,
      charmap: specialCharacters(this.editorMode),
      contextmenu: '',
      content_css: [
        `${resourcePath}assets/tinymce/common/content.css`,
        `${this.pluginsPath}mathlive/mathlive/mathlive.core.css`,
        `${this.pluginsPath}mathlive/mathlive/mathlive.css`
      ],
      font_size_formats: '15.3pt 21.2pt',
      icons: 'ne_icons',
      icons_url: `${resourcePath}assets/tinymce/common/icons/ne-icons/icons.js`,
      language: 'pl',
      language_url: `${resourcePath}assets/tinymce/langs/pl.js`,
      menubar: false,
      external_plugins: {
        mathlive: `${this.pluginsPath}mathlive/plugin.js`
      },
      plugins: [
        'autoresize',
        'charmap',
        'nonbreaking'
      ],
      relative_urls: false,
      skin_url: `${resourcePath}assets/tinymce/skins/ui/${this.skinName}`,
      toolbar: ['', this.toolbarButtons],
      verify_html: false,
      width: 680,
      height: 100,
      setup: (editor: any) => {
        (editor as any).additionalData = {
          subject: this.editorMode
        };
        this.addCustomButtons(editor);
        editor.mode.set(this.isCurrentVariantDisabled ? 'readonly' : 'design');
      }
    };
  }

  get customButtons (): EditorBasicButtonOptions[] {
    return this.isRadioButtonsVisible ? [
      {
        name: 'moveAnswerUp',
        icon: 'arrow-up',
        enabled: false,
        tooltip: this.$tc('EXERCISE_ANSWER.move_answer_up'),
        onAction: () => this.moveAnswer(true)
      },
      {
        name: 'moveAnswerDown',
        icon: 'arrow-down',
        tooltip: this.$tc('EXERCISE_ANSWER.move_answer_down'),
        onAction: () => this.moveAnswer(false)
      },
      {
        name: 'insert-image',
        icon: 'image',
        tooltip: this.$tc('EXERCISE_COMPOSER.editor.insert_image'),
        onAction: this.openInsertImageModal
      }
    ] : [
      {
        icon: 'image',
        name: 'insert-image',
        tooltip: this.$tc('EXERCISE_COMPOSER.editor.insert_image'),
        onAction: this.openInsertImageModal
      }
    ];
  }

  get group () {
    return this.type !== AnswersType.BOOLEAN ? GroupMap.get(this.index) : [...GroupMap.keys()][this.index] + 1;
  }

  get isInvalid (): boolean {
    if (this.type === AnswersType.ASSIGNMENT) {
      return Object.keys(this.answer).includes('correctAnswers') ? false : this.isValidationActive && !this.answer.content;
    }
    return this.isValidationActive && !this.answer.content;
  }

  get isCorrect (): boolean {
    if (this.type === AnswersType.BOOLEAN) {
      return this.answer.booleanAnswer !== null;
    } else if (this.type === AnswersType.ASSIGNMENT && (this.answer as ExerciseAnswerAdditional).correctAnswers) {
      return (this.answer as ExerciseAnswerAdditional).correctAnswers.length > 0;
    }
    return this.isRadioButtonsVisible ? this.answer.isCorrect : false;
  }

  openInsertImageModal () {
    this.isInsertImageModalOpen = true;
  }

  insertImage (image: { src: string, title: string}): void {
    this.closeInsertImageModal();
    insertImage(this.editorInstance.editor, image.src, image.title);
  }

  closeInsertImageModal (): void {
    this.isInsertImageModalOpen = false;
  }

  addCustomButtons (editor: any): void {
    this.customButtons.forEach((button: EditorBasicButtonOptions) => {
      editor.ui.registry.addButton(button.name, {
        icon: button.icon,
        tooltip: button.tooltip,
        text: button.text,
        onAction: button.onAction
      });
    });
  }

  focusEditor (): void {
    if (this.shouldFocus) this.editorInstance.editor.execCommand('mceFocus', true, 'tinymce');
  }

  onEditorInit () {
    this.editorInstance = this.$refs.editor;
    this.isLoaded = true;
    this.focusEditor();
  }

  getCorrectAnswers (items: MultiSelectItem[]): number[] {
    return items.filter(item => item.isSelected).map(item => item.id);
  }

  @Emit()
  moveAnswer (up: boolean): ExerciseAnswerMovePayload { return { answerIndex: this.index, up }; }

  @Emit() remove () {}

  @Emit() selectAnswer (): void {}

  @Emit() selectBooleanAnswer (value: number): { booleanAnswer: number, index: number } {
    return { booleanAnswer: value, index: this.index };
  }
}
