import { ExerciseAnswer, ExerciseAnswerAdditional } from '@/models/exercises';
import { deepClone } from '@/helpers/object-manipulation';

const defaultAnswer: ExerciseAnswer = {
  content: '',
  height: 0,
  isCorrect: false,
  booleanAnswer: null
};

const createAnswerFactory = (overwrites: Partial<ExerciseAnswer | ExerciseAnswerAdditional> = {}) => {
  return deepClone({
    ...defaultAnswer,
    ...overwrites
  });
};

export {
  createAnswerFactory
};
