

import { Component, Emit, Vue } from 'vue-property-decorator';
import { NeTile, NeIcon } from '@ne/ne-vue2-lib';

@Component({
  name: 'ExerciseImageInsertModalAddImage',
  components: {
    NeTile,
    NeIcon
  }
})

export default class ExerciseImageInsertModalAddImage extends Vue {
  @Emit() onAddImage (): void {}
}

