import { EditorModeName, MathliveMode, SpecialCharacter } from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/models';
import { editorMathliveModes } from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/helpers/mathlive';
import { editorCharacters } from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/helpers/special-characters';

export const setImageAttributes = (editor: any, src: string, title: string): void => {
  const query = `img[src='${src}']`;
  const img = editor.dom.select(query)[0];
  setTimeout(() => {
    if (img.naturalWidth > 0) {
      editor.dom.setAttribs(editor.dom.select(query), { width: img.clientWidth, height: img.clientHeight, name: title });
      editor.execCommand('mceAutoResize');
    }
  }, 500);
};

export const insertImage = (editor: any, imageSrc: string, title: string): void => {
  const content = `<img src="${imageSrc}"/>`;
  editor.insertContent(content);
  setImageAttributes(editor, imageSrc, title);
};

export const mathliveMode = (subject: EditorModeName): MathliveMode | null => {
  const mode = editorMathliveModes.find(item => item.editor === subject);
  return mode ? mode.mathliveMode : null;
};

export const specialCharacters = (subject: EditorModeName): SpecialCharacter[] => {
  return editorCharacters[subject];
};
