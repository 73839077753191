
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  NeButton,
  NeCheckbox,
  NeFieldValidator,
  NeFileUploadTile,
  NeIcon,
  NeSelect,
  NeTextField
} from '@ne/ne-vue2-lib';
import { Category } from '@/models/category-images';
import { SelectItem } from '@ne/ne-vue2-lib/types/components/NeSelect/models';
import { ValidationRule } from '@ne/ne-vue2-lib/types/types';

@Component({
  name: 'ExerciseImageUploaderPreview',
  components: { NeFileUploadTile, NeButton, NeIcon, NeSelect, NeTextField, NeCheckbox, NeFieldValidator }
})
export default class ExerciseImageUploaderPreview extends Vue {
  @Prop() save: () => {};
  @Prop() discard: () => {};
  @Prop() categoryImageId: number;
  @Prop() categories: Category[];
  @Prop() url: string;
  @Prop() imageName: string;
  name: string = '';
  selectedCategoryImageId: number | null = null;
  isBnW: boolean = false;
  isNameValid: boolean = true;
  maxImageNameLength = 30;

  mounted (): void {
    this.selectedCategoryImageId = this.categoryImageId;
    this.name = this.imageName;
  }

  get inputRules (): ValidationRule[] {
    const isWhiteSpaceOnly = (val: string) => val.trim().length === 0;
    const emptyName = (val: string) => { return (val && !isWhiteSpaceOnly(val) && val.length > 0) || this.$tc('EXERCISE_COMPOSER.image_uploader.enter_name'); };
    const maxLength = (val: string) => { return (val && val.length <= this.maxImageNameLength) || this.$t('EXERCISE_COMPOSER.image_uploader.image_name_length_exceeded', { maxLength: this.maxImageNameLength }).toString(); };
    return [emptyName, maxLength];
  }

  get mappedCategories (): SelectItem[] {
    return this.categories.map((el) => { return { id: el.id, text: `${el.name} (${el.count})` }; });
  }

  get isCategorySelectDisabled (): boolean {
    return this.categories.length <= 1;
  }

  async onSelectedCategoryImageChange (id: number): Promise<void> {
    this.selectedCategoryChanged(id);
  }

  @Emit()
  selectedCategoryChanged (id: number): number {
    this.selectedCategoryImageId = id;
    return id;
  }

  @Emit()
  parseToBW (): boolean {
    return this.isBnW;
  }

  @Emit()
  nameChanged (name: string): string {
    this.name = name;
    return this.name;
  }
}
