import { SortItem } from '../models';

export const sortItems: SortItem[] = [
  {
    text: 'data publikacji: najnowsze',
    id: 'dateDesc',
    metadata: {
      parameter: 'data publikacji',
      order: 'najnowsze'
    }
  },
  {
    text: 'data publikacji: najstarsze',
    id: 'date',
    metadata: {
      parameter: 'data publikacji',
      order: 'najstarsze'
    }
  },
  {
    text: 'nazwa: rosnąco',
    id: 'name',
    metadata: {
      parameter: 'nazwa',
      order: 'rosnąco (A-Z)'
    }
  },
  {
    text: 'nazwa: malejąco',
    id: 'nameDesc',
    metadata: {
      parameter: 'nazwa',
      order: 'malejąco (Z-A)'
    }
  }
];
