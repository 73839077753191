
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ExercisePreviewTemplateEnum } from '../../ExerciseTemplatePreviewModal/exercise-preview-template.enum';

@Component({
  name: 'ExerciseAnswersTemplatePreview',
  components: { }
})
export default class ExerciseAnswersTemplatePreview extends Vue {
  @Prop() template: ExercisePreviewTemplateEnum;

  get columnCount (): number {
    const templatesMap: number[] = [
      1,
      2,
      5
    ];
    return templatesMap[this.template];
  }
}
