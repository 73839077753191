import { ExercisePreviewTemplateEnum } from '../exercise-preview-template.enum';
import { ExerciseTemplate } from '@/models/exercises';

export const templates: ExerciseTemplate[] = [
  {
    title: 'Jedna kolumna',
    image: 'template-icon-5.png',
    type: ExercisePreviewTemplateEnum.ONE_COLUMN,
    template: ''
  },
  {
    title: 'Dwie kolumny',
    image: 'template-icon-4.png',
    type: ExercisePreviewTemplateEnum.TWO_COLUMNS,
    template: ''
  },
  {
    title: 'Trzy kolumny lub więcej',
    image: 'template-icon-8.png',
    type: ExercisePreviewTemplateEnum.MORE_COLUMNS,
    template: ''
  },
  {
    title: 'Prawda / Fałsz',
    image: 'template-icon-8.png',
    type: ExercisePreviewTemplateEnum.BOOLEAN_EXERCISE,
    template: ''
  }
];

export default templates;
