
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { NeRadioButton } from '@ne/ne-vue2-lib';
import { answersTypes } from '@/shared/data/exercises';
import { AnswersType, BooleanAnswer, BooleanAnswerOption, ExerciseAnswer } from '@/models/exercises';
import { EditorModeName } from '../../../ExerciseContentEditor/models';

@Component({
  name: 'BooleanExerciseAnswers',
  components: {
    NeRadioButton
  }
})
export default class BooleanExerciseAnswers extends Vue {
  @Prop() group: number;
  @Prop() index: number;
  @Prop() englishTemplate: number;
  @Prop() answer: ExerciseAnswer;
  @Prop() editorMode: EditorModeName;
  @Prop() isEnglish: boolean;
  @Prop() type: AnswersType;

  get name () {
    return `radio-${new Date().getTime()}`;
  }

  get booleanOptions () {
    const answerType = answersTypes.find(type => type.id === this.type)!;
    if (this.isEnglish) {
      return answerType?.options?.english.find((option: BooleanAnswerOption) => option.id === this.englishTemplate)?.templates;
    } else if (this.editorMode.includes('niemiecki')) {
      return answerType?.options?.german;
    }
    return answerType?.options?.default;
  }

  @Emit() selectBooleanAnswer (item: BooleanAnswer) {
    return item.value;
  }
}
