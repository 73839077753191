
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import ExerciseAnswers
  from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseAnswers/ExerciseAnswers.vue';
import { AnswersType, ExerciseAnswer, ExerciseAnswerAdditional, MultiSelectItem } from '@/models/exercises';
import { EditorModeName } from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/models';
import ExerciseConfigurationSection from '@/views/Generator/ExerciseComposer/components/ExerciseConfigurationSection';
import { ExerciseAnswerMovePayload } from '@/views/Generator/ExerciseComposer/models/ExerciseAnswerMovePayload';
import { GroupMap } from '@/helpers';
import { ValidationErrorEnum } from '@/views/Generator/ExerciseComposer/models';

@Component({
  name: 'ExerciseAssignment',
  components: {
    ExerciseAnswers,
    ExerciseConfigurationSection
  }
})
export default class ExerciseAssignment extends Vue {
  multiselectItems: MultiSelectItem[][] = [];
  shouldFocus = true;
  validationErrorsEnum = ValidationErrorEnum;

  @Prop() additionalAnswers: ExerciseAnswerAdditional[];
  @Prop() answers: ExerciseAnswer[];
  @Prop() categoryImageId: string;
  @Prop({ default: 0 }) englishTemplate: string;
  @Prop() isCurrentVariantDisabled: boolean;
  @Prop() isEarlySchoolEducation: boolean | null;
  @Prop() isEnglish: boolean;
  @Prop() isExerciseChangeDisabled: boolean;
  @Prop() isValidationActive: boolean;
  @Prop() mode: EditorModeName;
  @Prop() type: AnswersType;
  @Prop() validationErrors: unknown;

  mounted () {
    this.multiselectItems = this.getMultiselectItems(this.additionalAnswers, this.answers);
  }

  getMultiselectItems (additionalAnswers: ExerciseAnswerAdditional[], answers: ExerciseAnswer[]): MultiSelectItem[][] {
    return additionalAnswers.map((item, additionalAnswerIndex) => {
      return answers.map((answer, answerIndex) => {
        return {
          id: answerIndex,
          isSelected: additionalAnswers[additionalAnswerIndex].correctAnswers.includes(answerIndex),
          text: GroupMap.get(answerIndex) as string + '.'
        };
      });
    });
  }

  @Watch('additionalAnswers')
  @Watch('answers')
  answersAndAdditionalAnswersWatchHandler () {
    this.multiselectItems = this.getMultiselectItems(this.additionalAnswers, this.answers);
  }

  @Emit() addAnswer (): void { }

  @Emit()
  selectAnswer (index: number): number { return index; }

  @Emit()
  onMoveAnswer (payload: ExerciseAnswerMovePayload): ExerciseAnswerMovePayload {
    return payload;
  }

  @Emit()
  removeAnswer (index: number): number {
    return index;
  }
}
