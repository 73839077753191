
import { Component, Vue, Prop, PropSync, Emit, Watch } from 'vue-property-decorator';
import TinyMceEditor from '@tinymce/tinymce-vue';
// eslint-disable-next-line import/no-duplicates
import 'tinymce/tinymce';
// eslint-disable-next-line import/no-duplicates
import { Editor } from 'tinymce/tinymce';

// order of initialisation is important
import 'tinymce/themes/silver';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/code';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/template';
import 'tinymce/icons/default';
import 'tinymce/models/dom';
import { resourcePath } from '@/config';
import { SkinName } from '@/shared/components/TinyEditor/index';
import { templates } from '@/shared/components/TinyEditor/tiny-editor-config';
import { ExerciseImageInsertModal } from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/ExerciseImageInsertModal';
import {
  EditorContentType,
  EditorMode,
  SubjectCharacters
} from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/models';
import {
  insertImage
} from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/helpers/editor-utils';

@Component({
  name: 'TinyEditor',
  components: {
    TinyMceEditor,
    ExerciseImageInsertModal
  }
})
export default class TinyEditor extends Vue {
  $refs: {
    editor: any;
  };

  formula = '';
  isInsertImageModalOpen = false;
  pluginsPath = `${resourcePath}assets/tinymce/plugins/`;

  // get skin name from public/assets/tinymce/skins
  @Prop({ default: SkinName.TINY_MCE }) skinName: SkinName;
  @Prop() isExerciseOldType: boolean;
  @Prop() isEarlySchoolEducation: boolean;
  @Prop() categoryImageId: number;
  @Prop({ default: 'defaultId' }) editorId: string;
  @Prop() value: any;
  @Prop({ default: 'default' }) editorMode: EditorMode;
  @Prop() toolbarButtons: string;
  @Prop({ default: false }) isSolution: boolean;
  @Prop({ default: false }) isDisabled: boolean;
  @Prop() specialCharacters: SubjectCharacters;
  @Prop({ default: '' }) editorContentType: EditorContentType;

  @PropSync('editor') editorInstance: any;

  @Watch('isDisabled')
  onDisabledChange (isDisabled: boolean) {
    if (this.editorInstance.editor) this.editorInstance.editor.mode.set(isDisabled ? 'readonly' : 'design');
  }

  get editorConfig () {
    return {
      charmap: this.specialCharacters,
      content_css: [
        `${resourcePath}assets/tinymce/common/content.css`,
        `${this.pluginsPath}mathlive/mathlive/mathlive.core.css`,
        `${this.pluginsPath}mathlive/mathlive/mathlive.css`
      ],
      contextmenu: 'insert-answer',
      // this attribute need be false to avoid removing empty tags from mathlive formulas
      verify_html: false,
      external_plugins: {
        nesquare: `${this.pluginsPath}ne-square/plugin.js`,
        nedoubleunderline: `${this.pluginsPath}ne-double-underline/plugin.js`,
        badge: `${this.pluginsPath}badge/plugin.js`,
        mathlive: `${this.pluginsPath}mathlive/plugin.js`,
        ne_vertical_align: `${this.pluginsPath}ne-vertical-align/plugin.js`,
        ne_exercise_number_warning: `${this.pluginsPath}ne-exercise-number-warning/plugin.js`
      },
      font_size_formats: '15.3pt 21.2pt',
      formats: {
        badge: {
          title: 'badge',
          inline: 'span',
          classes: ['badge']
        },
        nedoubleunderline: {
          title: 'nedoubleunderline',
          inline: 'span',
          classes: ['double-underline']
        },
        top: {
          title: 'top',
          inline: 'span',
          classes: ['align'],
          attributes: {
            align: 'top'
          }
        },
        center: {
          title: 'center',
          inline: 'span',
          classes: ['align'],
          attributes: {
            align: 'center'
          }
        },
        bottom: {
          title: 'bottom',
          inline: 'span',
          classes: ['align'],
          attributes: {
            align: 'bottom'
          }
        }
      },
      icons: 'ne_icons',
      icons_url: `${resourcePath}assets/tinymce/common/icons/ne-icons/icons.js`,
      language: 'pl',
      language_url: `${resourcePath}assets/tinymce/langs/pl.js`,
      menubar: false,
      paste_as_text: true,
      placeholder: '',
      plugins: 'template table autoresize table badge nonbreaking visualchars visualblocks lists advlist charmap',
      relative_urls: false,
      resize: true,
      skin_url: `${resourcePath}assets/tinymce/skins/ui/${this.skinName}`,
      template: templates,
      theme: 'silver',
      toolbar: this.toolbarButtons,
      width: '680',
      min_height: 200,
      setup: (editor: Editor) => {
        (editor as any).additionalData = {
          subject: this.editorMode
        };
        this.initPlugins(editor);
        editor.mode.set(this.isDisabled ? 'readonly' : 'design');
      }
    };
  }

  initPlugins (editor: Editor) {
    this.setEditorInstance(editor);
    this.setInsertImageButton(editor);
    this.setInsertAnswerFieldButton(editor);
    if (this.editorContentType === EditorContentType.CONTENT) this.setInsertTaskMenuItem(editor);
    if (!this.isSolution) this.setInsertTemplateButton(editor);
    if (this.editorContentType === EditorContentType.SOLUTION) this.setCopyContentButton(editor);
  }

  setCopyContentButton (editor: Editor) {
    editor.ui.registry.addButton('copy-content', {
      tooltip: this.$tc('EXERCISE_COMPOSER.editor.copy_from_content'),
      text: this.$tc('EXERCISE_COMPOSER.editor.copy_from_content'),
      icon: 'copy',
      onAction: () => { this.onCopyContent(); }
    });
  }

  setInsertTaskMenuItem (editor: Editor): void {
    editor.ui.registry.addMenuItem('insert-answer', {
      text: this.$tc('EXERCISE_ANSWER.copy_to_empty_field'),
      icon: 'copy',
      onAction: () => {
        this.onInsertAnswer(editor.selection.getContent({ format: 'html' }));
      }
    });
  }

  setInsertTemplateButton (editor: Editor): void {
    editor.ui.registry.addButton('insert-template', {
      tooltip: this.$tc('EXERCISE_COMPOSER.editor.insert_template'),
      icon: 'template',
      onAction: () => { this.onInsertTemplate(); }
    });
  }

  setEditorInstance (editor: Editor) {
    this.editorInstance = editor;
  }

  setInsertAnswerFieldButton (editor: Editor) {
    editor.ui.registry.addButton('insert-answer', {
      tooltip: this.$tc('EXERCISE_COMPOSER.editor.answer_field'),
      icon: 'answer-field',
      onAction: () => {
        this.onAnswerFieldOpen();
      }
    });
  }

  setInsertImageButton (editor: Editor) {
    editor.ui.registry.addButton('ne-insert-image', {
      text: '',
      tooltip: this.$tc('EXERCISE_COMPOSER.editor.insert_image'),
      icon: 'image',
      onAction: () => {
        this.isInsertImageModalOpen = true;
      }
    });
  }

  closeInsertImageModal () {
    this.isInsertImageModalOpen = false;
  }

  insertImage (image: { src: string, title: string}) {
    insertImage(this.$refs.editor.editor, image.src, image.title);
    this.closeInsertImageModal();
  }

  @Emit()
  onInsertAnswer (answer: string): string {
    return answer;
  }

  @Emit() onAnswerFieldOpen () {}

  @Emit() onInsertTemplate () {}

  @Emit() onEditorInit () {
    this.editorInstance = this.$refs.editor;
  }

  @Emit() input (content: string): string {
    return content;
  }

  @Emit() blur () {}

  @Emit() change () {}

  @Emit() onCopyContent () {}
}
