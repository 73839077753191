
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import ExerciseAnswersItem from './ExerciseAnswersItem';
import { AnswersType, ExerciseAnswer, MultiSelectItem } from '@/models/exercises';
import { NeButton } from '@ne/ne-vue2-lib';
import { ExerciseAnswerMovePayload } from '@/views/Generator/ExerciseComposer/models/ExerciseAnswerMovePayload';
import {
  EditorModeName
} from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/models';
import {
  exerciseAnswerSubjectButtons
} from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/helpers/answers-subject-buttons';

@Component({
  name: 'ExerciseAnswers',
  components: {
    ExerciseAnswersItem,
    NeButton
  }
})
export default class ExerciseAnswers extends Vue {
  @Prop({ default: 'sel-add-answer-btn-0' }) addBtnId: string;
  @Prop() answers: ExerciseAnswer[];
  @Prop() categoryImageId: number;
  @Prop() englishTemplate: number;
  @Prop({ default: false }) isAssignment: boolean;
  @Prop() isCurrentVariantDisabled: boolean;
  @Prop() isEarlySchoolEducation: boolean | null;
  @Prop() isEnglish: boolean;
  @Prop() isExamBundle: boolean;
  @Prop() isExerciseChangeDisabled: boolean;
  @Prop({ default: false }) isNumericAnswer?: boolean;
  @Prop({ default: false }) isJustification?: boolean;
  @Prop() isValidationActive: boolean;
  @Prop() listItems: string[];
  @Prop({ default: 10 }) maxAnswersLength: number;
  @Prop({ default: 2 }) minAnswersLength: number;
  @Prop() mode: EditorModeName;
  @Prop({ default: null }) multiselectItems?: MultiSelectItem[][] | null;
  @Prop({ default: 'answers' }) radioGroupName: string;
  @Prop() shouldFocus: boolean;
  @Prop() type: AnswersType;

  get isRemoveButtonEnabled (): boolean {
    return this.answers.length > this.minAnswersLength;
  }

  get correctAnswerIndex (): number {
    return this.answers.findIndex(answer => answer.isCorrect);
  }

  get answersValues (): string[] {
    return this.answers.map(answer => answer.content);
  }

  get toolbarButtons (): string {
    const defaultButtons = 'undo redo bold italic ';
    const rightSideButtons = ' | moveAnswerUp moveAnswerDown';
    const subjectButtons = exerciseAnswerSubjectButtons[this.mode] ?? '';
    return defaultButtons.concat(subjectButtons, rightSideButtons);
  }

  get isAddButtonEnabled (): boolean {
    return this.answers.length < this.maxAnswersLength && !this.isCurrentVariantDisabled;
  }

  isLastIndex (index: number, arrLength: number): boolean {
    return index === arrLength - 1;
  }

  @Emit()
  onMoveAnswer (payload: ExerciseAnswerMovePayload): ExerciseAnswerMovePayload {
    payload.answers = this.answers;
    return payload;
  }

  @Emit()
  removeAnswer (index: number): number {
    return index;
  }

  @Emit() addAnswer (): void {}

  @Emit() selectAnswer (index: number) { return index; }

  @Emit() selectBooleanAnswer (booleanAnswer: number) { return booleanAnswer; }
}
