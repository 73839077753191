
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  EditorModeName
} from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/models';
import { NeButton, NeIconButton } from '@ne/ne-vue2-lib';
import { ExerciseHint } from '@/models/exercises';
import {
  exerciseAnswerSubjectButtons
} from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/helpers/answers-subject-buttons';
import TinyMceEditor from '@tinymce/tinymce-vue';
import ExerciseAnswersItem
  from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseAnswers/ExerciseAnswersItem';

@Component({
  name: 'ExerciseHints',
  components: { ExerciseAnswersItem, NeButton, NeIconButton, TinyMceEditor }
})
export default class ExerciseHints extends Vue {
  @Prop({ default: 'sel-add-hint-btn-0' }) addBtnId: string;
  @Prop() hints: ExerciseHint[];
  @Prop() categoryImageId: number;
  @Prop() isExerciseChangeDisabled: boolean;
  @Prop() isCurrentVariantDisabled: boolean;
  @Prop() isEarlySchoolEducation: boolean;
  @Prop() mode: EditorModeName;

  editorInstance: any;
  isLoaded = false;
  objectId: number = Math.floor(Math.random() * 100000000);

  get isAddButtonEnabled (): boolean {
    return this.hints.length < 3;
  }

  get toolbarButtons (): string {
    const defaultButtons = 'undo redo bold italic ';
    const rightSideButtons = ' | moveAnswerUp moveAnswerDown';
    const subjectButtons = exerciseAnswerSubjectButtons[this.mode] ?? '';
    return defaultButtons.concat(subjectButtons, rightSideButtons);
  }

  @Emit() addHint (): void {}

  @Emit() removeHint (index: number): number { return index; }
}
