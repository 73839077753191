
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { AnswersType, AnswerType, ExerciseType } from '@/models/exercises';
import ExerciseAnswerTypesTile from './ExerciseAnswerTypesTile/ExerciseAnswerTypesTile.vue';
import { namespace } from 'vuex-class';
import { isExerciseOldType } from '@/helpers/exercise';
import { LayoutModel } from '@/store/layout-store/types';

const LayoutStore = namespace('LayoutStore');

@Component({
  name: 'ExerciseAnswerTypes',
  components: { ExerciseAnswerTypesTile }
})
export default class ExerciseAnswerTypes extends Vue {
  @Prop() answerTypes: AnswerType[];
  @Prop() currentExerciseType: AnswersType;
  @Prop() isAnswerTypeChangeDisabled: boolean;
  @Prop() isEarlyEducation: boolean;
  @Prop() isSuperUser: boolean;
  @Prop() exerciseTypes!: ExerciseType;


  @LayoutStore.State('layout') layout: LayoutModel;

  get showOndorio (): boolean | null {
    return this.layout.user && this.layout.user.isOndorio;
  }

  get currentAnswerType (): AnswerType | undefined {
    return this.answerTypes.find(answerType => answerType.id === this.currentExerciseType);
  }

  iconSrc (answerTypeId: AnswersType): string {
    return isExerciseOldType(answerTypeId)
      ? require('@/assets/images/icons/exercise-type/open.svg')
      : require(`@/assets/images/icons/exercise-type/${this.answerTypeIcon(answerTypeId)}.svg`);
  }

  answerTypeIcon (answerTypeId: AnswersType): string {
    const iconNameMap: { [key in AnswersType]: string } = {
      [AnswersType.CLOSED]: 'closed',
      [AnswersType.OPEN_CLOSED]: 'open-closed',
      [AnswersType.OPEN]: 'open',
      [AnswersType.SINGLE_CHOICE]: 'single-choice',
      [AnswersType.MULTIPLE_CHOICE]: 'multiple-choice',
      [AnswersType.BOOLEAN]: 'boolean',
      [AnswersType.BUNDLES]: 'bundles',
      [AnswersType.BUNDLES_EXAM]: 'bundles',
      [AnswersType.NUMERIC]: 'szkolnictwo-zawodowe',
      [AnswersType.CUSTOM_LIST]: 'szkolnictwo-zawodowe',
      [AnswersType.TEXT]: 'szkolnictwo-zawodowe',
      [AnswersType.ASSIGNMENT]: 'assignment',
      [AnswersType.ORDER_SETTING]: 'szkolnictwo-zawodowe',
      [AnswersType.LIST]: 'szkolnictwo-zawodowe',
      [AnswersType.JUSTIFICATION]: 'justification',
      [AnswersType.FLASHCARD]: 'open'
    };
    return iconNameMap[answerTypeId];
  }

  shouldShowLabel (answerTypeId: AnswersType) : boolean {
    const newAnswerTypesArr = [
      AnswersType.SINGLE_CHOICE,
      AnswersType.MULTIPLE_CHOICE,
      AnswersType.BOOLEAN
    ];
    return newAnswerTypesArr.includes(answerTypeId);
  }

  @Emit()
  setAnswerType (answerType: AnswerType): AnswerType {
    return answerType;
  }
}
