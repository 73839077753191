import { SubjectCharacters } from '../models';

export const editorCharacters: SubjectCharacters = {
  default: [],
  angielski_sp: [
    ['8216', 'left single quotation mark'],
    ['9723', 'ballot box'],
    ['8217', 'right single quotation mark'],
    ['8220', 'left double quotation mark'],
    ['8221', 'right double quotation mark'],
    ['8218', 'single low-9 quotation mark'],
    ['8222', 'double low-9 quotation mark'],
    ['162', 'cent sign'],
    ['8364', 'euro sign'],
    ['163', 'pound sign'],
    ['165', 'yen sign'],
    ['196', 'A - diaeresis'],
    ['214', 'O - diaeresis'],
    ['220', 'U - diaeresis'],
    ['228', 'a - diaeresis'],
    ['246', 'o - diaeresis'],
    ['252', 'u - diaeresis'],
    ['946', 'beta'],
    ['233', 'café']
  ],
  angielski_sp1_3: [
    ['176', 'degree sign'],
    ['8901', 'multiplication sign'],
    ['8758', 'division sign']
  ],
  angielski_spp: [
    ['8216', 'left single quotation mark'],
    ['9723', 'ballot box'],
    ['8217', 'right single quotation mark'],
    ['8220', 'left double quotation mark'],
    ['8221', 'right double quotation mark'],
    ['8218', 'single low-9 quotation mark'],
    ['8222', 'double low-9 quotation mark'],
    ['162', 'cent sign'],
    ['8364', 'euro sign'],
    ['163', 'pound sign'],
    ['165', 'yen sign'],
    ['196', 'A - diaeresis'],
    ['214', 'O - diaeresis'],
    ['220', 'U - diaeresis'],
    ['228', 'a - diaeresis'],
    ['246', 'o - diaeresis'],
    ['252', 'u - diaeresis'],
    ['946', 'beta'],
    ['233', 'café']
  ],
  biologia_sp: [
    ['8240', 'per mille sign'],
    ['9723', 'ballot box'],
    ['176', 'degree sign'],
    ['247', 'division sign'],
    ['913', 'Alpha'],
    ['914', 'Beta'],
    ['923', 'Lambda'],
    ['934', 'Phi'],
    ['945', 'alpha'],
    ['946', 'beta'],
    ['955', 'lambda'],
    ['966', 'phi'],
    ['181', 'micro sign'],
    ['8592', 'leftwards arrow'],
    ['8594', 'rightwards arrow'],
    ['8596', 'left right arrow']
  ],
  biologia_spp: [
    ['8240', 'per mille sign'],
    ['9723', 'ballot box'],
    ['176', 'degree sign'],
    ['247', 'division sign'],
    ['913', 'Alpha'],
    ['914', 'Beta'],
    ['923', 'Lambda'],
    ['934', 'Phi'],
    ['945', 'alpha'],
    ['946', 'beta'],
    ['955', 'lambda'],
    ['966', 'phi'],
    ['181', 'micro sign'],
    ['8592', 'leftwards arrow'],
    ['8594', 'rightwards arrow'],
    ['8596', 'left right arrow']
  ],
  chemia_sp: [],
  chemia_spp: [],
  edb_sp: [
    ['8364', 'euro sign'],
    ['9723', 'ballot box'],
    ['163', 'pound sign'],
    ['8242', 'minutes / feet'],
    ['167', 'section sign'],
    ['8217', 'right single quotation mark'],
    ['8224', 'dagger'],
    ['224', 'a - grave'],
    ['225', 'a - acute'],
    ['228', 'a - diaeresis'],
    ['231', 'c - cedilla'],
    ['232', 'e - grave'],
    ['233', 'e - acute'],
    ['235', 'e - diaeresis'],
    ['242', 'o - grave'],
    ['243', 'o - acute'],
    ['246', 'o - diaeresis'],
    ['353', 's - caron'],
    ['249', 'u - grave'],
    ['250', 'u - acute'],
    ['252', 'u - diaeresis'],
    ['253', 'y - acute'],
    ['8592', 'leftwards arrow'],
    ['8593', 'upwards arrow'],
    ['8594', 'rightwards arrow'],
    ['8595', 'downwards arrow'],
    ['8596', 'left right arrow']
  ],
  edb_spp: [
    ['8216', 'left single quotation mark'],
    ['8217', 'right single quotation mark'],
    ['8220', 'left double quotation mark'],
    ['8221', 'right double quotation mark'],
    ['8218', 'single low-9 quotation mark'],
    ['8222', 'double low-9 quotation mark'],
    ['8364', 'euro sign'],
    ['196', 'A - diaeresis'],
    ['214', 'O - diaeresis'],
    ['220', 'U - diaeresis'],
    ['228', 'a - diaeresis'],
    ['246', 'o - diaeresis'],
    ['9723', 'ballot box'],
    ['252', 'u - diaeresis'],
    ['946', 'beta']
  ],
  fizyka_sp: [],
  fizyka_spp: [],
  geografia_sp: [
    ['34', 'quotation mark'],
    ['8242', 'minutes / feet'],
    ['8243', 'seconds / inches'],
    ['37', 'procent'],
    ['8240', 'per mille sign'],
    ['8216', 'left single quotation mark'],
    ['8217', 'right single quotation mark'],
    ['8220', 'left double quotation mark'],
    ['8221', 'right double quotation mark'],
    ['8218', 'single low-9 quotation mark'],
    ['8222', 'double low-9 quotation mark'],
    ['176', 'degree sign'],
    ['8722', 'minus sign'],
    ['247', 'division sign'],
    ['8776', 'almost equal to'],
    ['8800', 'not equal to'],
    ['180', 'acute accent'],
    ['945', 'alpha'],
    ['946', 'beta'],
    ['948', 'delta'],
    ['955', 'lambda'],
    ['966', 'phi'],
    ['9723', 'ballot box'],
    ['8594', 'rightwards arrow'],
    ['8596', 'left right arrow']
  ],
  geografia_spp: [
    ['34', 'quotation mark'],
    ['8242', 'minutes / feet'],
    ['8243', 'seconds / inches'],
    ['37', 'procent'],
    ['8240', 'per mille sign'],
    ['8216', 'left single quotation mark'],
    ['8217', 'right single quotation mark'],
    ['8220', 'left double quotation mark'],
    ['8221', 'right double quotation mark'],
    ['8218', 'single low-9 quotation mark'],
    ['8222', 'double low-9 quotation mark'],
    ['176', 'degree sign'],
    ['8722', 'minus sign'],
    ['247', 'division sign'],
    ['8776', 'almost equal to'],
    ['8800', 'not equal to'],
    ['180', 'acute accent'],
    ['945', 'alpha'],
    ['946', 'beta'],
    ['948', 'delta'],
    ['955', 'lambda'],
    ['966', 'phi'],
    ['9723', 'ballot box'],
    ['8594', 'rightwards arrow'],
    ['8596', 'left right arrow']
  ],
  historia_sp: [
    ['8364', 'euro sign'],
    ['9723', 'ballot box'],
    ['163', 'pound sign'],
    ['8242', 'minutes / feet'],
    ['167', 'section sign'],
    ['8217', 'right single quotation mark'],
    ['8224', 'dagger'],
    ['224', 'a - grave'],
    ['225', 'a - acute'],
    ['228', 'a - diaeresis'],
    ['231', 'c - cedilla'],
    ['232', 'e - grave'],
    ['233', 'e - acute'],
    ['235', 'e - diaeresis'],
    ['242', 'o - grave'],
    ['243', 'o - acute'],
    ['246', 'o - diaeresis'],
    ['353', 's - caron'],
    ['249', 'u - grave'],
    ['250', 'u - acute'],
    ['252', 'u - diaeresis'],
    ['253', 'y - acute'],
    ['8592', 'leftwards arrow'],
    ['8593', 'upwards arrow'],
    ['8594', 'rightwards arrow'],
    ['8595', 'downwards arrow'],
    ['8596', 'left right arrow'],
    ['176', 'degree sign']
  ],
  historia_spp: [
    ['8364', 'euro sign'],
    ['9723', 'ballot box'],
    ['163', 'pound sign'],
    ['8242', 'minutes / feet'],
    ['167', 'section sign'],
    ['8217', 'right single quotation mark'],
    ['8224', 'dagger'],
    ['224', 'a - grave'],
    ['225', 'a - acute'],
    ['228', 'a - diaeresis'],
    ['231', 'c - cedilla'],
    ['232', 'e - grave'],
    ['233', 'e - acute'],
    ['235', 'e - diaeresis'],
    ['242', 'o - grave'],
    ['243', 'o - acute'],
    ['246', 'o - diaeresis'],
    ['353', 's - caron'],
    ['249', 'u - grave'],
    ['250', 'u - acute'],
    ['252', 'u - diaeresis'],
    ['253', 'y - acute'],
    ['8592', 'leftwards arrow'],
    ['8593', 'upwards arrow'],
    ['8594', 'rightwards arrow'],
    ['8595', 'downwards arrow'],
    ['8596', 'left right arrow'],
    ['176', 'degree sign']
  ],
  matematyka_sp1_3: [
    ['176', 'degree sign'],
    ['8901', 'multiplication sign'],
    ['8758', 'division sign'],
    ['8722', 'minus sign']
  ],
  matematyka_sp: [],
  matematyka_spp: [],
  niemiecki_sp: [
    ['8216', 'left single quotation mark'],
    ['8217', 'right single quotation mark'],
    ['8220', 'left double quotation mark'],
    ['8221', 'right double quotation mark'],
    ['8218', 'single low-9 quotation mark'],
    ['8222', 'double low-9 quotation mark'],
    ['8364', 'euro sign'],
    ['196', 'A - diaeresis'],
    ['214', 'O - diaeresis'],
    ['220', 'U - diaeresis'],
    ['228', 'a - diaeresis'],
    ['246', 'o - diaeresis'],
    ['9723', 'ballot box'],
    ['252', 'u - diaeresis'],
    ['946', 'beta']
  ],
  niemiecki_spp: [
    ['8216', 'left single quotation mark'],
    ['8217', 'right single quotation mark'],
    ['8220', 'left double quotation mark'],
    ['8221', 'right double quotation mark'],
    ['8218', 'single low-9 quotation mark'],
    ['8222', 'double low-9 quotation mark'],
    ['8364', 'euro sign'],
    ['196', 'A - diaeresis'],
    ['214', 'O - diaeresis'],
    ['220', 'U - diaeresis'],
    ['228', 'a - diaeresis'],
    ['246', 'o - diaeresis'],
    ['9723', 'ballot box'],
    ['252', 'u - diaeresis'],
    ['946', 'beta']
  ],
  polski_sp: [
    ['8211', '-'],
    ['8216', 'left single quotation mark'],
    ['8217', 'right single quotation mark'],
    ['8220', 'left double quotation mark'],
    ['8221', 'right double quotation mark'],
    ['9723', 'ballot box'],
    ['8218', 'single low-9 quotation mark'],
    ['8222', 'double low-9 quotation mark'],
    ['162', 'cent sign'],
    ['8364', 'euro sign'],
    ['163', 'pound sign'],
    ['165', 'yen sign']
  ],
  polski_spp: [
    ['8211', '-'],
    ['8216', 'left single quotation mark'],
    ['8217', 'right single quotation mark'],
    ['8220', 'left double quotation mark'],
    ['8221', 'right double quotation mark'],
    ['9723', 'ballot box'],
    ['8218', 'single low-9 quotation mark'],
    ['8222', 'double low-9 quotation mark'],
    ['162', 'cent sign'],
    ['8364', 'euro sign'],
    ['163', 'pound sign'],
    ['165', 'yen sign']
  ],
  przedsiebiorczosc_spp: [
    ['8216', 'left single quotation mark'],
    ['8217', 'right single quotation mark'],
    ['8220', 'left double quotation mark'],
    ['8221', 'right double quotation mark'],
    ['8218', 'single low-9 quotation mark'],
    ['8222', 'double low-9 quotation mark'],
    ['8364', 'euro sign'],
    ['196', 'A - diaeresis'],
    ['214', 'O - diaeresis'],
    ['220', 'U - diaeresis'],
    ['228', 'a - diaeresis'],
    ['246', 'o - diaeresis'],
    ['9723', 'ballot box'],
    ['252', 'u - diaeresis'],
    ['946', 'beta']
  ],
  przyroda_sp: [],
  przyroda_spp: [],
  technika_sp: [
    ['8211', '-'],
    ['8216', 'left single quotation mark'],
    ['8217', 'right single quotation mark'],
    ['8220', 'left double quotation mark'],
    ['8221', 'right double quotation mark'],
    ['9723', 'ballot box'],
    ['8218', 'single low-9 quotation mark'],
    ['8222', 'double low-9 quotation mark'],
    ['162', 'cent sign'],
    ['8364', 'euro sign'],
    ['163', 'pound sign'],
    ['165', 'yen sign']
  ],
  wos_sp: [
    ['8364', 'euro sign'],
    ['9723', 'ballot box'],
    ['163', 'pound sign'],
    ['8242', 'minutes / feet'],
    ['167', 'section sign'],
    ['8217', 'right single quotation mark'],
    ['8224', 'dagger'],
    ['224', 'a - grave'],
    ['225', 'a - acute'],
    ['228', 'a - diaeresis'],
    ['231', 'c - cedilla'],
    ['232', 'e - grave'],
    ['233', 'e - acute'],
    ['235', 'e - diaeresis'],
    ['242', 'o - grave'],
    ['243', 'o - acute'],
    ['246', 'o - diaeresis'],
    ['353', 's - caron'],
    ['249', 'u - grave'],
    ['250', 'u - acute'],
    ['252', 'u - diaeresis'],
    ['253', 'y - acute'],
    ['8592', 'leftwards arrow'],
    ['8593', 'upwards arrow'],
    ['8594', 'rightwards arrow'],
    ['8595', 'downwards arrow'],
    ['8596', 'left right arrow']
  ],
  wos_spp: [
    ['8364', 'euro sign'],
    ['9723', 'ballot box'],
    ['163', 'pound sign'],
    ['8242', 'minutes / feet'],
    ['167', 'section sign'],
    ['8217', 'right single quotation mark'],
    ['8224', 'dagger'],
    ['224', 'a - grave'],
    ['225', 'a - acute'],
    ['228', 'a - diaeresis'],
    ['231', 'c - cedilla'],
    ['232', 'e - grave'],
    ['233', 'e - acute'],
    ['235', 'e - diaeresis'],
    ['242', 'o - grave'],
    ['243', 'o - acute'],
    ['246', 'o - diaeresis'],
    ['353', 's - caron'],
    ['249', 'u - grave'],
    ['250', 'u - acute'],
    ['252', 'u - diaeresis'],
    ['253', 'y - acute'],
    ['8592', 'leftwards arrow'],
    ['8593', 'upwards arrow'],
    ['8594', 'rightwards arrow'],
    ['8595', 'downwards arrow'],
    ['8596', 'left right arrow']
  ]
};
