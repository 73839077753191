import { Variant } from '@/models/exercises';
import { deepClone } from '@/helpers/object-manipulation';

const defaultVariant: Variant = {
  answerKey: false,
  answers: null,
  additionalAnswers: null,
  author: 'Nowa Era',
  bundles: null,
  content: '<p>Variant content</p>',
  hints: null,
  id: 321882,
  justificationWord: null,
  printHeight: 143,
  printHeightSolution: 36
};

const createVariantFactory = (overwrites: Partial<Variant> = {}) => {
  return deepClone({
    ...defaultVariant,
    ...overwrites
  });
};

export {
  createVariantFactory
};
