
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import ExerciseAnswers from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseAnswers';
import { AnswersType, Bundle } from '@/models/exercises';
import ExerciseContentEditor from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor';
import { NeButton, NeIcon } from '@ne/ne-vue2-lib';
import ExerciseAnswersItem
  from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseAnswers/ExerciseAnswersItem/ExerciseAnswersItem.vue';
import ExerciseTemplatePicker
  from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseTemplatePicker/ExerciseTemplatePicker.vue';
import { ExerciseAnswerMovePayload } from '@/views/Generator/ExerciseComposer/models/ExerciseAnswerMovePayload';
import ConfirmationModal from '@/shared/components/ConfirmationModal/ConfirmationModal.vue';
import ExerciseConfigurationSection from '@/views/Generator/ExerciseComposer/components/ExerciseConfigurationSection';
import {
  EditorContentType,
  EditorModeName
} from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/models';
import { createAnswerFactory } from '@/mocks/factories/createAnswerMock';
import ExerciseHints
  from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseHints/ExerciseHints.vue';
import { TextField } from '@/shared/components';
import { ExercisePreviewTemplateEnum } from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseTemplatePreviewModal/exercise-preview-template.enum';

@Component({
  name: 'ExerciseBundles',
  components: {
    ConfirmationModal,
    ExerciseTemplatePicker,
    ExerciseAnswersItem,
    ExerciseAnswers,
    ExerciseContentEditor,
    ExerciseConfigurationSection,
    ExerciseHints,
    NeButton,
    NeIcon,
    TextField
  }
})
export default class ExerciseBundles extends Vue {
  isContentModified = false;
  answersType = AnswersType;
  shouldFocus: boolean = false;
  isRemoveConfirmationModalOpen = false;
  editorContentTypes = EditorContentType;

  @Prop() bundle: Bundle;
  @Prop() bundleIndex: number;
  @Prop() categoryImageId: string;
  @Prop() englishTemplate: string;
  @Prop() isCurrentVariantDisabled: boolean;
  @Prop() isEarlySchoolEducation: boolean | null;
  @Prop() isEnglish: boolean;
  @Prop() isExamBundle: boolean;
  @Prop() isExerciseChangeDisabled: boolean;
  @Prop() isExerciseHeightValidationActive: boolean;
  @Prop() isExerciseOldType: boolean;
  @Prop() isValidationActive: boolean;
  @Prop() maxContentHeight: number;
  @Prop() mode: EditorModeName;
  @Prop() showRemoveBtn: boolean;
  @Prop() type: AnswersType;
  @Prop() validationErrors: unknown;
  @Prop() variantIndex: number;

  @Prop() answerTemplatesText: string[];

  get editorId (): string {
    return `subcontent__${Math.floor(Math.random() * 100000000)}`;
  }

  addAnswer (): void {
    this.shouldFocus = true;
    this.bundle.answers?.push({ ...createAnswerFactory() });
  }

  removeAnswer (index: number): void {
    this.bundle.answers!.splice(index, 1);
  }

  addHint (): void {
    this.bundle.hints?.push({ content: '' });
  }

  removeHint (index: number): void {
    this.bundle.hints!.splice(index, 1);
  }

  onAnswerTemplateChange (index: ExercisePreviewTemplateEnum): void {
    this.bundle.template = index;
  }

  openRemoveConfirmationModal (): void {
    this.isRemoveConfirmationModalOpen = true;
  }

  closeRemoveConfirmationModal (): void {
    this.isRemoveConfirmationModalOpen = false;
  }

  @Emit()
  onSelectAnswer (index: number): number {
    return index;
  }

  @Emit()
  onMoveAnswer (payload: ExerciseAnswerMovePayload): ExerciseAnswerMovePayload {
    return payload;
  }

  @Emit()
  onInsertAnswer (answer: string): string {
    return answer;
  }

  @Emit()
  removeBundle (): number {
    this.closeRemoveConfirmationModal();
    return this.bundleIndex;
  }

  @Emit() insertTemplate () {}
}
