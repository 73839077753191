
export type EditorModeName =
  | 'default'
  | 'angielski_sp'
  | 'angielski_sp1_3'
  | 'angielski_spp'
  | 'biologia_sp'
  | 'biologia_spp'
  | 'chemia_sp'
  | 'chemia_spp'
  | 'fizyka_sp'
  | 'fizyka_spp'
  | 'geografia_sp'
  | 'geografia_spp'
  | 'historia_sp'
  | 'historia_spp'
  | 'matematyka_sp'
  | 'matematyka_sp1_3'
  | 'matematyka_spp'
  | 'niemiecki_sp'
  | 'niemiecki_spp'
  | 'polski_sp'
  | 'polski_spp'
  | 'przedsiebiorczosc_spp'
  | 'przyroda_sp'
  | 'przyroda_spp'
  | 'technika_sp'
  | 'wos_sp'
  | 'wos_spp';

export enum EditorMode {
  DEFAULT = 'default',
  ANGIELSKI_SP = 'angielski_sp',
  ANGIELSKI_SP1_3 = 'angielski_sp1_3',
  ANGIELSKI_SPP = 'angielski_spp',
  BIOLGIA_SP = 'biologia_sp',
  BIOLGIA_SPP = 'biologia_spp',
  CHEMIA_SP = 'chemia_sp',
  CHEMIA_SPP = 'chemia_spp',
  EDB_SP = 'edb_sp',
  EDB_SPP = 'edb_spp',
  FIZYKA_SP = 'fizyka_sp',
  FIZYKA_SPP = 'fizyka_spp',
  GEOGRAFIA_SP = 'geografia_sp',
  GEOGRAFIA_SPP = 'geografia_spp',
  HISTORIA_SP = 'historia_sp',
  HISTORIA_SPP = 'historia_spp',
  MATEMATYKA_SP = 'matematyka_sp',
  MATEMATYKA_SP1_3 = 'matematyka_sp1_3',
  MATEMATYKA_SPP = 'matematyka_spp',
  NIEMIECKI_SP = 'niemiecki_sp',
  NIEMIECKI_SPP = 'niemiecki_spp',
  POLSKI_SP = 'polski_sp',
  POLSKI_SPP = 'polski_spp',
  PRZEDSIEBIORCZOSC_SPP = 'przedsiebiorczosc_spp',
  PRZYRODA_SP = 'przyroda_sp',
  PRZYRODA_SPP = 'przyroda_spp',
  TECHNIKA_SP = 'technika_sp',
  WOS_SP = 'wos_sp',
  WOS_SPP = 'wos_spp'
}

export type PluginName =
  | 'nesquare'
  | 'ne-table-helper'
  | 'ne-empty-lines'
  | 'ne-double-underline'
  | 'ne-exercise-number-warning'
  | 'kartka'
  | 'chemia'
  | 'chemicalFormulas'
  | 'jpolski'
  | 'mathlive'
  | 'charmap';

export enum Plugin {
  NE_SQUARE = 'nesquare',
  NE_TABLE_HELPER = 'ne-table-helper',
  NE_EMPTY_LINES = 'ne-empty-lines',
  NE_DOUBLE_UNDERLINE = 'ne-double-underline',
  NE_EXERCISE_NUMBER_WARNING = 'ne-exercise-number-warning',
  KARTKA = 'kartka',
  CHEMIA = 'chemia',
  CHEMICAL_FORMULAS = 'chemicalFormulas',
  JPOLSKI = 'jpolski',
  MATHLIVE = 'mathlive',
  SPECIAL_CHARACTERS = 'special-characters'
}

export type SubjectPlugins = {
  [key in EditorMode]: PluginName[];
};

export type SubjectButtons = {
  [key in EditorMode]: string;
};

export interface PluginLocation {
  name: PluginName;
  path: string;
}

export type ExternalPlugins = {
  [propName in Plugin]?: string;
};

export type MathliveMode = 'matematyka_sp' | 'chemia_sp' | 'fizyka_sp'| 'fizyka_spp' | 'biologia_sp'| 'biologia_spp' | 'matematyka_spp' | 'chemia_spp';

export interface EditorMathliveMode {
  editor: EditorModeName;
  mathliveMode: MathliveMode;
}

export type SpecialCharacter = [string, string];

export type SubjectCharacters = {
  [key in EditorMode]: SpecialCharacter[];
};

export interface CustomAnswerFieldTypes {
  name: string;
  label: string;
}

export type AnswerFieldTypesPerSubject = {
  [key in EditorMode]: CustomAnswerFieldTypes[];
};

export enum FieldType {
  CHECKER = 'checker',
  LINE = 'line',
  MULTILINE = 'multiline',
  EMPTY = 'empty'
}

export enum EditorContentType {
  CONTENT = 'content',
  SOLUTION = 'solution',
  BUNDLE_SUB_CONTENT = 'bundle_sub_content',
  ADDITIONAL_CONTENT = 'additional_content'
}
