
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { NeIcon } from '@ne/ne-vue2-lib';
import { ExerciseImageUploader } from './ExerciseImageUploader';
import { ExerciseImageViewer } from './ExerciseImageViewer';
import { IMAGES_CATEGORY } from '@/api/eob/endpoints';
import { Category } from '@/models/category-images';
import { Modal, ModalBody, ModalHeader } from '@/shared/components';

@Component({
  name: 'ExerciseImageInsertModal',
  components: { Modal,
    ModalHeader,
    ModalBody,
    ExerciseImageViewer,
    ExerciseImageUploader,
    NeIcon }
})
export default class ExerciseImageInsertModal extends Vue {
  @Prop() isInsertImageModalOpen: boolean;
  @Prop() categoryImageId: number;

  @Emit() closeInsertImageModal (): void {}

  @Emit() onInsertImage (image: { src: string, title: string}): { src: string, title: string} {
    return { src: image.src, title: image.title };
  }

  isUploadView = false;
  selectedCategoryImageId = 1;
  categories: Category[] = [];

  mounted (): void {
    this.getCategoryImages();
  }

  toggleUploadView (): void {
    this.isUploadView = !this.isUploadView;
  }

  async getCategoryImages (): Promise<void> {
    this.categories = (await this.$api.get<any>(IMAGES_CATEGORY(this.categoryImageId))).data;
  }

  onImageUpdate (): void {
    this.getCategoryImages();
  }

  changeSelectedCategory (id: number): void {
    this.selectedCategoryImageId = id;
  }
}
