
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Variant, Source } from '@/models/exercises';
import {
  NeButton,
  NeIcon
} from '@ne/ne-vue2-lib';
import { deepClone } from '@/helpers/object-manipulation';

@Component({
  name: 'ExerciseVariantPicker',
  components: {
    NeButton,
    NeIcon
  }
})
export default class ExerciseVariantPicker extends Vue {
  @Prop() value!: number;
  @Prop() variants: Variant[];
  @Prop() currentVariant: Variant;
  @Prop() isSuperUser: boolean;
  @Prop() isPreviewModal: boolean;

  @Emit() setVariantIndex (index: number): number {
    return index - 1;
  }

  isLocked (index: number): boolean {
    const variant = this.variants[index];
    return !variant || (variant.author !== Source.MY && !this.isSuperUser);
  }

  @Emit()
  addVariant (): Variant {
    const variant: Variant = deepClone(this.currentVariant);
    if (variant.answers) variant.answers.forEach(variant => { variant.id = null; });
    if (variant.bundles) {
      variant.bundles!.forEach(bundle => {
        delete bundle.id;
        bundle.answers!.forEach(answer => {
          delete answer.id;
        });
      });
    }
    variant.author = this.isSuperUser ? Source.NOWA_ERA : Source.MY;
    delete variant.id;
    return variant;
  }
}
