
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { NeButton, NeRadioButton } from '@ne/ne-vue2-lib';
import { ExercisePreviewTemplateEnum } from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseTemplatePreviewModal/exercise-preview-template.enum';
import ExerciseAnswersTemplatePreview
  from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseAnswers/ExerciseAnswersTemplatePreview/ExerciseAnswersTemplatePreview.vue';

@Component({
  name: 'ExerciseTemplatePicker',
  components: {
    NeButton,
    ExerciseAnswersTemplatePreview,
    NeRadioButton
  }
})

export default class ExerciseTemplatePicker extends Vue {
  @Prop() answerTemplatesText: string[];
  @Prop() answersTemplate: ExercisePreviewTemplateEnum | null;
  @Prop() isExerciseChangeDisabled: boolean;
  @Prop() headlineText: string;

  @Emit()
  onAnswerTemplateChange (index: ExercisePreviewTemplateEnum): ExercisePreviewTemplateEnum {
    return index;
  }
}
