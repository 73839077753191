
import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { NeSpinner } from '@ne/ne-vue2-lib';
import { ExerciseImageInsertModal } from './ExerciseImageInsertModal';
import { ExerciseAnswerFieldModal } from './ExerciseAnswerFieldModal';

import { TextEditor } from '@/shared/components/TextEditor';
import { CustomAnswerFieldTypes, EditorContentType, EditorModeName, PluginName } from './models';
import { subjectPlugins } from './helpers/plugins';
import { answerFieldTypesPerSubject } from './helpers/answer-fields';
import {
  insertImage,
  setImageAttributes,
  specialCharacters
} from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/helpers/editor-utils';
import TinyEditor from '@/shared/components/TinyEditor/TinyEditor.vue';
import TinyMceEditor from '@tinymce/tinymce-vue';

@Component({
  name: 'ExerciseContentEditor',
  components: {
    TinyEditor,
    ExerciseAnswerFieldModal,
    ExerciseImageInsertModal,
    NeSpinner,
    TextEditor,
    TinyMceEditor
  }
})
export default class ExerciseContentEditor extends Vue {
  @Prop() categoryImageId: number;
  @Prop() earlySchoolEducation: boolean | null;
  @Prop() editorId!: string;
  @Prop() isEditable!: boolean;
  @Prop() isExerciseOldType: boolean;
  @Prop() maxHeight: number;
  @Prop() mode!: EditorModeName | null;
  @Prop() showPlaceholder!: boolean;
  @Prop() value!: string;
  @Prop() variantIndex!: number;
  @Prop({ default: '' }) editorContentType: EditorContentType;
  @Prop({ default: false }) isBooleanSubContent: boolean;
  @Prop({ default: false }) showContextMenu: boolean;
  @PropSync('height') contentHeight!: number;
  @PropSync('hovered') isHovered!: boolean;
  @PropSync('invalid') isInvalid!: boolean;

  isLoaded = false;
  isDisabled = !this.isEditable;
  validatorTimeout: NodeJS.Timer | null = null;
  isInsertImageModalOpen = false;
  isAnswerFieldModalOpen = false;
  editor: any = {};

  $refs: {
    editor: any;
  };

  get subject (): EditorModeName {
    return this.mode || 'default';
  }

  get activeAdvancedPlugins (): PluginName[] {
    return subjectPlugins[this.subject];
  }

  get specialCharacters () {
    return specialCharacters(this.subject);
  }

  get toolbarButtons (): string [] {
    if (this.earlySchoolEducation) {
      return [
        'undo redo | forecolor backcolor | removeformat nonbreaking visualchars visualblocks | ',
        'fontsize bold italic underline nedoubleunderline strikethrough badge | subscript superscript',
        `alignleft aligncenter alignright alignjustify | outdent indent | bullist numlist | ne-vertical-align nesquare insert-answer ${this.activeAdvancedPlugins} table ne-insert-image insert-template`,
        'copy-content'
      ];
    }
    return [
      'undo redo | bold italic underline nedoubleunderline strikethrough badge | subscript superscript | forecolor backcolor | removeformat nonbreaking visualchars visualblocks | ',
      `alignleft aligncenter alignright alignjustify | outdent indent | bullist numlist | ne-vertical-align nesquare insert-answer ${this.activeAdvancedPlugins} table ne-insert-image insert-template`,
      'copy-content'
    ];
  }

  get answerFieldTypes (): CustomAnswerFieldTypes[] {
    for (const key in answerFieldTypesPerSubject) {
      if (key === this.mode) {
        return answerFieldTypesPerSubject[key];
      }
    }
    return answerFieldTypesPerSubject.default;
  }

  beforeDestroy (): void {
    this.clearValidatorTimeout();
  }

  @Watch('value', { immediate: false })
  onValueChange (): void {
    this.clearValidatorTimeout();
    this.validatorTimeout = setTimeout(() => {
    }, 100);
  }

  @Watch('isEditable', { immediate: true })
  handleEditorActivity (): void {
    setTimeout(() => {
      this.isDisabled = !this.isEditable;
    }, 100);
  }

  clearValidatorTimeout (): void {
    if (this.validatorTimeout) clearTimeout(this.validatorTimeout);
  }

  insertBlock (block: string, isImage = false, imageSrc: string): void {
    this.editor.editor.insertContent(block);
    if (isImage) {
      setImageAttributes(this.editor.editor, imageSrc, '');
    }
  }

  closeInsertImageModal (): void {
    this.isInsertImageModalOpen = false;
  }

  insertImage (image: { src: string, title: string}): void {
    insertImage(this.editor.editor, image.src, image.title);
    this.isInsertImageModalOpen = false;
  }

  @Emit() blur () {}

  @Emit() change () {}

  @Emit()
  copyContent (): void {
    // This enforces editor to update contentValue after tiny-mathlive insert. GT-4732
    this.editor.editor.execCommand('mceAutoResize');
  }

  @Emit()
  insertAnswer (answer: string): string {
    return answer;
  }

  @Emit() input (content: string): string {
    return content;
  }

  @Emit()
  insertTemplate (): EditorContentType {
    return this.editorContentType;
  }
}
