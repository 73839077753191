

import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { NeIcon, NeTile } from '@ne/ne-vue2-lib';
import { Tooltip } from '@/shared/components';

@Component({
  name: 'ExerciseImageInsertModalTile',
  components: {
    NeTile,
    NeIcon,
    Tooltip
  }
})

export default class ExerciseImageInsertModalTile extends Vue {
  @Prop() src: string;
  @Prop() title: string;
  @Prop() count: number;
  @Prop() useInExercise: boolean;
  @Prop() canRemove: boolean;
  @Prop() id: number;
  timeToDelete = 5;
  isDeleting = false;
  isDeleted = false;
  timeOut: NodeJS.Timeout;
  interval: NodeJS.Timer;
  isHoverOnDeleteWrapper = false;

  get tileClass () {
    return {
      'gt-exercise-image-insert-modal-tile--deleting': this.isDeleting,
      'gt-exercise-image-insert-modal-tile--deleted': this.isDeleted,
      'gt-exercise-image-insert-modal-tile--delete-hovered': this.isHoverOnDeleteWrapper,
      'gt-exercise-image-insert-modal-tile--use-in-exercise': this.useInExercise
    };
  }

  deleteImage (): void {
    if (!this.useInExercise) {
      this.isDeleting = true;
      this.isHoverOnDeleteWrapper = false;
      this.interval = setInterval(this.decreaseTime, 1000);
      this.timeOut = setTimeout(() => {
        clearInterval(this.interval);
        this.isDeleting = false;
        this.isDeleted = true;
        this.onImageRemove(this.id);
      }, 5000);
    }
  }

  decreaseTime () {
    this.timeToDelete -= 1;
  }

  resetImageState (): void {
    clearTimeout(this.timeOut);
    clearInterval(this.interval);
    this.isDeleting = false;
    this.timeToDelete = 5;
  }

  tileAction () {
    if (this.isDeleting) {
      this.resetImageState();
    } else {
      this.onInsertImage();
    }
  }

  @Emit() onImageRemove (id: number): number {
    return id;
  }

  @Emit() onInsertImage (): {src: string, title: string} {
    return { src: this.src, title: this.title };
  }
}

