
import { Component, Emit, Prop, PropSync, Vue } from 'vue-property-decorator';
import ExerciseAnswers
  from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseAnswers/ExerciseAnswers.vue';
import ExerciseConfigurationSection from '@/views/Generator/ExerciseComposer/components/ExerciseConfigurationSection';
import { AnswersType, ExerciseAnswer } from '@/models/exercises';
import { EditorModeName } from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseContentEditor/models';
import { NeTextField } from '@ne/ne-vue2-lib';
import { ExerciseAnswerMovePayload } from '@/views/Generator/ExerciseComposer/models/ExerciseAnswerMovePayload';
import { ValidationErrorEnum } from '@/views/Generator/ExerciseComposer/models';

@Component({
  name: 'ExerciseJustification',
  components: {
    ExerciseAnswers,
    ExerciseConfigurationSection,
    NeTextField
  }
})
export default class ExerciseJustification extends Vue {
  answerTypeEnum = AnswersType;
  validationErrorEnum = ValidationErrorEnum;

  @Prop() answersType: AnswersType;
  @Prop() categoryImageId: string;
  @Prop() englishTemplate: string;
  @Prop() isCurrentVariantDisabled: boolean;
  @Prop() isEarlySchoolEducation: boolean | null;
  @Prop() isEnglish: boolean;
  @Prop() isExerciseChangeDisabled: boolean;
  @Prop() isValidationActive: boolean;
  @Prop() additionalAnswers: ExerciseAnswer[]
  @PropSync('justificationWordValue') justificationWord: string;
  @Prop() mode: EditorModeName;
  @Prop() shouldFocus: boolean;
  @Prop() validationErrors: unknown;

  @Emit() addAnswer (): void { }

  @Emit()
  selectAnswer (index: number): number { return index; }

  @Emit()
  onMoveAnswer (payload: ExerciseAnswerMovePayload): ExerciseAnswerMovePayload {
    return payload;
  }

  @Emit()
  removeAnswer (index: number): number {
    return index;
  }
}
