
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  FileIcon,
  Panel,
  PanelHeader,
  PanelBody,
  FileUploader
} from '@/shared/components';
import { ExerciseCreateData } from '@/models/exercises';
import { NeButton } from '@ne/ne-vue2-lib';

@Component({
  name: 'ExerciseAttachment',
  components: {
    Panel,
    PanelHeader,
    PanelBody,
    FileUploader,
    FileIcon,
    NeButton
  }
})
export default class ExerciseAttachment extends Vue {
  @Prop() exercise: ExerciseCreateData;
  @Prop() isLoaded: boolean;
  @Prop() isEditable: boolean;

  newAttachmentName: string | null = null;
  acceptedExtensions: string[] = [
    'doc',
    'docx',
    'gif',
    'jpg',
    'mp3',
    'mp4',
    'pdf',
    'png',
    'ppt',
    'pptx',
    'rar',
    'tiff',
    'txt',
    'uni',
    'xls',
    'xlsx',
    'zip'
  ];

  get initialFile (): string {
    return this.exercise.attachments && this.exercise.attachments[0] ? this.exercise.attachments[0].filename : '';
  }

  get filename (): string {
    return this.newAttachmentName || this.initialFile;
  }

  get extension (): string {
    return this.filename.split('.').slice(-1)[0];
  }

  clearAttachments (): void {
    this.exercise.attachments = [];
  }

  removeAttachment () {
    this.clearAttachments();
    delete this.exercise.newAttachment;
    this.newAttachmentName = null;
  }

  uploadAttachment (file: File): void {
    this.clearAttachments();
    this.newAttachmentName = file.name;
    this.$set(this.exercise, 'newAttachment', file);
  }
}
