
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { AnswersType, AnswerType, ExerciseType } from '@/models/exercises';
import { NeIcon } from '@ne/ne-vue2-lib';
import { Tooltip } from '@/shared/components';
import { isExerciseOldType } from '@/helpers/exercise';
import { namespace } from 'vuex-class';
import { LayoutModel } from '@/store/layout-store/types';

const LayoutStore = namespace('LayoutStore');

@Component({
  name: 'ExerciseAnswerTypesTile',
  components: { NeIcon, Tooltip }
})
export default class ExerciseAnswerTypesTile extends Vue {
  @Prop() answerType: AnswerType;
  @Prop() answerTypeId: AnswersType;
  @Prop() iconName: string;
  @Prop() isActive: boolean;
  @Prop() isAnswerTypeChangeDisabled: boolean;
  @Prop() isExerciseOldType: boolean;
  @Prop() isEarlyEducation: boolean
  @Prop() isTooltipDisabled: boolean;
  @Prop({ default: '' }) hasLabel: boolean;
  @Prop() isSuperUser: boolean;
  @Prop() exerciseTypes!: ExerciseType;


  @LayoutStore.State('layout') layout: LayoutModel;

  get showOndorio (): boolean | null {
    return this.layout.user && this.layout.user.isOndorio;
  }

  get isTileOldType (): boolean | null {
    return isExerciseOldType(this.answerTypeId);
  }

  get iconUrl (): string {
    return this.isTileOldType
      ? require('@/assets/images/icons/exercise-type/open.svg')
      : require(`@/assets/images/icons/exercise-type/${this.iconName}.svg`);
  }

  get isAnswerTypeTileVisible (): boolean {
    return this.exerciseTypes.hasOwnProperty(this.answerTypeId)
  }

  @Emit()
  setAnswerType (): AnswerType {
    return this.answerType;
  }
}
