import { AnswersType, Bundle } from '@/models/exercises';
import { ExercisePreviewTemplateEnum } from '@/views/Generator/ExerciseComposer/components/ExerciseContent/ExerciseTemplatePreviewModal/exercise-preview-template.enum';
import { deepClone } from '@/helpers/object-manipulation';
import { createAnswerFactory } from '@/mocks/factories/createAnswerMock';

const defaultBundle: Bundle = {
  answers: [createAnswerFactory(), createAnswerFactory()],
  answersType: AnswersType.SINGLE_CHOICE,
  hints: [{ content: '' }],
  isContentInvalid: false,
  printHeight: 0,
  printHeightSolution: 0,
  score: 1,
  subContent: '',
  template: ExercisePreviewTemplateEnum.MORE_COLUMNS
};

const createBundleFactory = (overwrites: Partial<Bundle> = {}) => {
  return deepClone({
    ...defaultBundle,
    ...overwrites
  });
};

export {
  createBundleFactory
};
