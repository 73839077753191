export const loadCss = (fileUrl: string) => {
  const file = document.createElement('link');
  file.rel = 'stylesheet';
  file.href = fileUrl;
  document.head.appendChild(file);
};

export const unloadCss = (fileUrl: string) => {
  const styles = document.styleSheets;
  for (const style of styles) {
    if (style.href === fileUrl) {
      style.disabled = true;
    }
  }
};
