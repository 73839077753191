
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

import { resourcePath } from '@/config';
import TinyEditor from '@/shared/components/TinyEditor/TinyEditor.vue';

@Component({
  name: 'exercise-configuration-editor',
  components: {
    TinyEditor
  }
})
export default class ExerciseContentEditor extends Vue {
  @Prop() editorId: string;
  @Prop() value!: string;
  @Prop({ default: true, type: Boolean }) isEditable!: boolean;

  cssAssets: string[] = ['assets/tinymce/common/content.css'];
  editor: any = {};
  isDisabled = !this.isEditable;
  objectId: number = Math.floor(Math.random() * 100000000);

  get specialCharacters () {
    return [
      ['34', 'quotation mark'],
      ['8242', 'minutes / feet'],
      ['8243', 'seconds / inches'],
      ['37', 'procent'],
      ['8240', 'per mille sign'],
      ['8216', 'left single quotation mark'],
      ['8217', 'right single quotation mark'],
      ['8220', 'left double quotation mark'],
      ['8221', 'right double quotation mark'],
      ['8218', 'single low-9 quotation mark'],
      ['8222', 'double low-9 quotation mark'],
      ['176', 'degree sign'],
      ['8722', 'minus sign'],
      ['247', 'division sign'],
      ['8776', 'almost equal to'],
      ['8800', 'not equal to'],
      ['180', 'acute accent'],
      ['945', 'alpha'],
      ['946', 'beta'],
      ['948', 'delta'],
      ['955', 'lambda'],
      ['966', 'phi'],
      ['9723', 'ballot box'],
      ['8594', 'rightwards arrow'],
      ['8596', 'left right arrow'],
      ['8901', 'multiplication sign'],
      ['8758', 'division sign']
    ];
  }

  get cmpCssAssets (): string[] {
    return this.cssAssets.map(asset => `${resourcePath}${asset}`);
  }

  get toolbarButtons (): string[] {
    return ['bold italic underline | strikethrough subscript superscript | charmap'];
  }

  @Watch('isEditable', { immediate: true })
  handleEditorActivity (): void {
    setTimeout(() => {
      this.isDisabled = !this.isEditable;
    }, 100);
  }

  @Emit()
  input (content: string): string {
    return content;
  }
}
